import React from 'react';
import { ToastContainer as MUIToast, cssTransition } from 'material-react-toastify';
import { makeStyles, Theme } from '@material-ui/core';

import 'material-react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css';

import { usePlayerState } from '../PlayerProvider';
import useChatContext from '../hooks/useChatContext/useChatContext';
import clsx from 'clsx';

const fadeTransition = cssTransition({
  enter: 'animate__fadeInUp',
  exit: 'animate__fadeOutUp',
  duration: 500,
});

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '&.Toastify__toast-container--top-right': {
      top: '90px',
      right: '40px',
      transition: 'top 0.25s ease',
    },
    '&.Toastify__toast-container--bottom-center': {
      bottom: '140px',
      transtiion: 'bottom 0.25s ease',
      [theme.breakpoints.down('md')]: {
        bottom: '108px',
      },
    },
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0 8px',
    },
  },
  toast: {
    backgroundColor: '#FFF',
    padding: '12px 20px',
    borderRadius: '30px',
  },
  body: {
    color: '#111',
    fontSize: '9px',
  },
}));

export default function ChatAlertContainer() {
  const classes = useStyles();
  const { playerState } = usePlayerState();
  const { isChatWindowOpen } = useChatContext();

  if (playerState === 'disconnected' || isChatWindowOpen) return null;
  return (
    <MUIToast
      className={clsx(classes.container, 'animate__animated')}
      toastClassName={classes.toast}
      bodyClassName={classes.body}
      position="bottom-center"
      autoClose={9000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      closeButton={false}
      limit={1}
      transition={fadeTransition}
    />
  );
}
