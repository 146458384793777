import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function IconSpeaker(props) {
  return (
    <SvgIcon viewBox="0 0 53 44" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8483 0.188845C27.5511 0.518004 28 1.22399 28 2.00002V42C28 42.776 27.5511 43.482 26.8483 43.8112C26.1455 44.1403 25.3158 44.0332 24.7196 43.5364L10.8759 32H5.99999C2.68629 32 0 29.3137 0 26V18C0 14.6863 2.68629 12 5.99999 12H10.8759L24.7196 0.463581C25.3158 -0.0332238 26.1455 -0.140314 26.8483 0.188845Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5563 6.58579C40.7752 5.80474 39.5089 5.80474 38.7278 6.58579C37.9468 7.36683 37.9468 8.63316 38.7278 9.41421C45.7573 16.4436 45.7573 27.8406 38.7278 34.87C37.9468 35.6511 37.9468 36.9174 38.7278 37.6984C39.5089 38.4795 40.7752 38.4795 41.5563 37.6984C50.1478 29.1069 50.1478 15.1773 41.5563 6.58579ZM35.8994 12.2426C35.1184 11.4616 33.8521 11.4616 33.071 12.2426C32.29 13.0237 32.29 14.29 33.071 15.0711C36.9763 18.9763 36.9763 25.3079 33.071 29.2132C32.29 29.9942 32.29 31.2606 33.071 32.0416C33.8521 32.8226 35.1184 32.8226 35.8994 32.0416C41.3668 26.5743 41.3668 17.71 35.8994 12.2426Z"
      />
    </SvgIcon>
  );
}

export default IconSpeaker;
