import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconInvite(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M31 15C34.3137 15 37 12.3137 37 9C37 5.68629 34.3137 3 31 3C27.6863 3 25 5.68629 25 9C25 12.3137 27.6863 15 31 15ZM30 7C30 6.44772 30.4477 6 31 6C31.5523 6 32 6.44772 32 7V8H33C33.5523 8 34 8.44772 34 9C34 9.55228 33.5523 10 33 10H32V11C32 11.5523 31.5523 12 31 12C30.4477 12 30 11.5523 30 11V10H29C28.4477 10 28 9.55228 28 9C28 8.44772 28.4477 8 29 8H30V7Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31 17C31.2072 17 31.4126 16.9921 31.6159 16.9766C31.8666 17.9425 32 18.9557 32 20C32 26.6274 26.6274 32 20 32C13.3726 32 8 26.6274 8 20C8 13.3726 13.3726 8 20 8C21.0443 8 22.0575 8.1334 23.0234 8.38409C23.0079 8.58737 23 8.79276 23 9C23 13.4183 26.5817 17 31 17ZM18 20C18 21.1046 17.1046 22 16 22C14.8954 22 14 21.1046 14 20C14 18.8954 14.8954 18 16 18C17.1046 18 18 18.8954 18 20ZM26 20C26 21.1046 25.1046 22 24 22C22.8954 22 22 21.1046 22 20C22 18.8954 22.8954 18 24 18C25.1046 18 26 18.8954 26 20Z" />
    </SvgIcon>
  );
}

export default IconInvite;
