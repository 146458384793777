import React, { useMemo } from 'react';
import clsx from 'clsx';
import linkify from 'linkify-it';
import { makeStyles } from '@material-ui/core/styles';
import LinkPreview from '../LinkPreview/LinkPreview';

const useStyles = makeStyles({
  messageContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.25em 0',
    margin: '0',
    wordBreak: 'break-word',
    // backgroundColor: '#E1E3EA',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    '& a': {
      color: '#111',
    },
  },
  author: {
    color: '#999999',
    textTransform: 'uppercase',
  },
});

interface TextMessageProps {
  body: string;
  isLocalParticipant?: boolean;
  author: string;
}

export default function TextMessage({ body, isLocalParticipant, author }: TextMessageProps) {
  const classes = useStyles();

  function addLinks(text: string) {
    const matches = linkify()
      .tlds('video', true)
      .add('vibe:', 'https:')
      .match(text);
    if (!matches) return text;

    const results = [];
    let lastIndex = 0;

    matches.forEach((match, i) => {
      results.push(text.slice(lastIndex, match.index));
      results.push(<LinkPreview url={match.url} key={i} />);
      lastIndex = match.lastIndex;
    });

    if (lastIndex !== text.length) results.push(<span className={classes.author}>{author}</span>); // Add author if after link
    results.push(text.slice(lastIndex, text.length));

    return results;
  }

  const text = useMemo(() => {
    return addLinks(body);
  }, [body]);

  return (
    <div className={classes.messageContainer}>
      <div>
        <span className={classes.author}>{author}</span> {text}
      </div>
    </div>
  );
}
