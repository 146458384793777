import React from 'react';

export default function IconSend() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5858 21.4142C9.80474 20.6332 9.80474 19.3668 10.5858 18.5858L18.5858 10.5858C19.3668 9.80474 20.6332 9.80474 21.4142 10.5858L29.4142 18.5858C30.1953 19.3668 30.1953 20.6332 29.4142 21.4142C28.6332 22.1953 27.3668 22.1953 26.5858 21.4142L22 16.8284V28C22 29.1046 21.1046 30 20 30C18.8954 30 18 29.1046 18 28V16.8284L13.4142 21.4142C12.6332 22.1953 11.3668 22.1953 10.5858 21.4142Z"
        fill="black"
      />
    </svg>
  );
}
