import { createTheme } from '@material-ui/core/styles/';

const breakpointValues = {
  xs: 0,
  sm: 521,
  md: 960,
  lg: 1280,
  xl: 1920,
};

let fonts = ['NB Akademie Std', 'Source Sans Pro', 'Arial', 'sans-serif'];

export const customTheme = {
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
  mixins: {
    CTRLTitle: {
      fontSize: '40px',
      lineHeight: '40px',
      fontWeight: 600,
      margin: '30px',
    },
    CTRLSecondaryTitle: {
      fontSize: '30px',
      lineHeight: '40px',
      fontWeight: 600,
      margin: '40px',
      [`@media(max-width:${breakpointValues.sm}px)`]: {
        fontSize: 20,
        lineHeight: '30px',
      },
    },
  },
  breakpoints: {
    values: breakpointValues,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#ffffff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '12px',
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#111111',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 1)',
          color: '#FFF',
        },
      },
      contained: {
        '&:hover': {
          backgroundColor: '#111',
          color: '#ffffff',
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#111',
          color: '#ffffff',
        },
      },
      outlined: {
        '&:hover': {
          backgroundColor: '#111',
          color: '#ffffff',
        },
      },
    },
    MuiFab: {
      root: {
        backgroundColor: '#ffffff',
        color: '#111111',
        width: '60px',
        height: '60px',
        boxShadow: 'none',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: '#111',
          color: '#ffffff',
        },
      },
    },
  },
  typography: {
    fontFamily: fonts.join(','),
    allVariants: {
      color: '#111',
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1600,
    snackbar: 1400,
    tooltip: 1500,
  },
  sidebarWidth: window.innerWidth / 2,
  sidebarMobileHeight: 90,
  // sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  chatWindowWidth: 320,
};

export const createNewMuiTheme = (options) => createTheme(options);

export default createTheme(customTheme);