import React, { createContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { useParams } from 'react-router';
import { useContext } from 'react';
import axios from 'axios';
import nodeConfig from './config/axios';

export const PlayerContext = createContext(null);

// initialize websockets
let socket;
if (window.location.pathname === '/') socket = io(process.env.REACT_APP_DOMAIN).connect(); 

export const PlayerProvider = ({ children }) => {
  
  let persistPlayerState = {
    player: false,
    playerState: 'disconnected',
    muxState: '',
    currentMessage: '',
    viewerInfo: '',
    vibeConfig: '',
    firstCheck: false,
    stream: '',
    timeUp: '',
  };

  persistPlayerState = JSON.parse(sessionStorage.getItem('playerState')) || persistPlayerState;

  const [player, setPlayer] = useState(persistPlayerState.player);
  const [playerState, setPlayerState] = useState(persistPlayerState.playerState);
  const [muxState, setMuxState] = useState(persistPlayerState.muxState);
  const [currentMessage, setCurrentMessage] = useState(persistPlayerState.currentMessage);
  const [viewerInfo, setViewerInfo] = useState(persistPlayerState.viewerInfo);
  const [vibeConfig, setVibeConfig] = useState(persistPlayerState.vibeConfig);
  const [firstCheck, setFirstCheck] = useState(persistPlayerState.firstCheck);
  const [stream, setStream] = useState(persistPlayerState.stream);
  const [timeUp, setTimeUp] = useState(persistPlayerState.timeUp);
  const { URLRoomName } = useParams();
  const socketRef = useRef();
  const timeOut = useRef(null);

  // get vibe config if needed
  useEffect(() => {
    const updatedState = {
      player,
      playerState,
      muxState,
      currentMessage,
      viewerInfo,
      vibeConfig,
      firstCheck,
      stream,
      timeUp,
    };

    sessionStorage.setItem('playerState', JSON.stringify(updatedState));
  }, [player, playerState, muxState, currentMessage, viewerInfo, vibeConfig, firstCheck, stream])

  useEffect(() => {
    if (URLRoomName) {

      const URLVibeChannelParam = URLRoomName.split('|')[1];
      const urlRoomParam = URLRoomName.split('|')[2];
      const participantURLParam = URLRoomName.split('|')[3];

      const urlParams = new window.URLSearchParams({ channelURLParam: URLVibeChannelParam, showURLParam: urlRoomParam, participantURLParam });

      nodeConfig.get(`get-vibe-config?${urlParams}`) // fetch that occurs if naviagte to session url
        .then(response => response.json())
        .then(jsondata => {
          // console.log('fetching from FE', jsondata);
        })
        .catch(e => {
         console.log("e", e);
        });
    }
    return () => {
      // Close socket.io connection
      socket?.close()
      socket?.removeAllListeners();
      // Remove timeout reference
      timeOut.current = null;
    }
  // eslint-disable-next-line
  }, []);

  const setCurrentSettings = (key, value) => {
    persistPlayerState[key] = value;
  };

  const connect = (info) => {
    setCurrentSettings('player', true);
    setPlayer(true);
    setCurrentSettings('viewerInfo', info);
    setViewerInfo(info);
  };

  const disconnect = () => {
    setCurrentSettings('player', false);
    setPlayer(false);

    setCurrentSettings('playerState', 'disconnected');
    setPlayerState('disconnected');
    
    setCurrentSettings('viewerInfo', null);
    setViewerInfo(null);
    
    setCurrentSettings('firstCheck', false);
    setFirstCheck(false);

    setCurrentSettings('currentMessage', '');
    setCurrentMessage('');

    setCurrentSettings('stream', '');
    setStream('');

    setCurrentSettings('timeOut', '');
    setTimeUp('');
    sessionStorage.removeItem('timeOut');
  };

  const disconnecting = () => {
    setCurrentSettings('playerState', 'disconnecting')
    setPlayerState('disconnecting');

    setTimeout(() => {
      disconnect();
    }, 1000);
  }

  // handle incoming messages
  const handleMessage = (data) => {
    //console.log("incoming message", data);
    setCurrentSettings('currentMessage', data.message);
    setCurrentMessage(data.message);
    
    if (data.go_live) {
      setCurrentSettings('currentMessage', `go_live=${data.go_live}`);
      setCurrentMessage(`go_live=${data.go_live}`);
    }
  };

  useEffect(() => {
    socket?.on('message', handleMessage)
  }, [socket]);

  useEffect(() => {
    if (firstCheck) {
      if (playerState === 'connecting' && (currentMessage === 'video.live_stream.active' || currentMessage === 'go_live=true')) {
        setCurrentSettings('playerState', 'connected');
        setPlayerState('connected');
      };
      // Handle reconnections
      if (playerState === 'reconnecting' && currentMessage === 'video.live_stream.connected') {
        setCurrentSettings('playerState', 'connected');
        setPlayerState('connected');
        clearTimeout(timeOut.current);
      };
      // Handle disconnections
      if (playerState === 'connected' && currentMessage === 'video.live_stream.disconnected') {
        setCurrentSettings('playerState', 'reconnecting');
        setPlayerState('reconnecting');

        sessionStorage.setItem('timeOut', new Date().getTime());
        timeOut.current = setTimeout(() => {
          disconnecting();
        }, stream?.reconnect_window * 1000 * 1);
      }

      if (currentMessage === 'video.live_stream.idle') disconnect();
    }
  }, [currentMessage, firstCheck])

  const getStreamData = async () => {
    try {
      const streamState = await nodeConfig.get({
        url: 'retrieve-stream',
      });
      return streamState.data;
    } catch (err) {
      console.log("error", err);
      return;
    }
  }

  useEffect(() => {
    var timeOutStarted;
    if (sessionStorage.getItem('timeOut')) {
      const currentTime = new Date().getTime();
      timeOutStarted = sessionStorage.getItem('timeOut');
      if ((currentTime - timeOutStarted) > (timeUp * 1000)) disconnect();
      else {
        timeOut.current = setTimeout(() => {
          disconnecting();
        }, (timeUp * 1000 - (currentTime - timeOutStarted)));
      }
    }
  }, []);

  useEffect(() => {
    async function getStreamInfo () {

      if (playerState === 'connecting') {
        // get current live streaming state
        if (!process.env.REACT_APP_TEST_PLAYER) {
          const streamData = await getStreamData();
          //console.log("streamData", streamData);
          // Set current live stream id 
          if (streamData) {
            setStream(streamData);
            setCurrentSettings('stream', streamData); 
  
            setCurrentSettings('timeOut', streamData.reconnect_window);
            setTimeUp(streamData.reconnect_window);
          }
  
          // if live streaming is active set player state as connected
          if (streamData?.status === 'active') {
            setCurrentSettings('playerState', 'connected');
            setPlayerState('connected');
          }
          // After checking current stream id state
          setCurrentSettings('firstCheck', true);
          setFirstCheck(true);
        } else {
          setCurrentSettings('playerState', 'connected');
          setPlayerState('connected');

          setCurrentSettings('firstCheck', true);
          setFirstCheck(true);
        }
      }
     
    }
    getStreamInfo()
  }, [playerState]);
  
  useEffect(() => {
    if (!player && firstCheck) {
      socket.removeAllListeners();
      socket.close();
    }
  }, [playerState]);

  useEffect(() => {
    // Step 1
    if (player && playerState === 'disconnected') {
      setCurrentSettings('playerState', 'connecting');
      setPlayerState('connecting');
    }
    if (!player) {
      //setCurrentSettings('playerState', 'disconnected');
      //setPlayerState('disconnected');
    }
  }, [player]);

  /* useEffect(() => {
    const getStreamMessages = async () => {
      if (vibeConfig && player && syncStream) {
        syncStream.on('messagePublished', event => {
          console.log('Player received a message', event);
          const message = event.message.data.host;
          if (message === 'VibeBreakLobby') setPlayerState('connected');
        });
      }
    };
    getStreamMessages();
  }, [vibeConfig, player, syncStream]); */

  //console.log("playerState out", playerState);
  //console.log("player out", player);
  //console.log("first check", firstCheck);
  //console.log("currentMessage", currentMessage);
  return (
    <PlayerContext.Provider value={{ connect, disconnect, playerState, viewerInfo, vibeConfig, stream, currentMessage }}>{children}</PlayerContext.Provider>
  );
};

export function usePlayerState() {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
