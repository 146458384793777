import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

//import { isMobile, isIOS } from '../../utils';

import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { useParams } from 'react-router-dom';
//import useNoiseGif from '../../hooks/useNoiseGif/useNoiseGif';

//import MediaErrorSnackbar from '../PreJoinScreens/MediaErrorSnackbar/MediaErrorSnackbar';

//import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';

import './blink.css';
import './fade.css';

import axios from 'axios';
import { usePlayerState } from '../../PlayerProvider';
import IconArrowRight from '../../Icons/IconArrowRight';
import FullScreenAppBackground from '../../FullScreenAppBackground';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import _ from 'lodash';

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      position: 'relative',
      height: '100vh',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '400px',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        width: '90%'
      },
    },
    fab: {
      margin: '0',
      '&$disabledFab': {
        color: '#111',
      },
    },
    disabledFab: {},
    fabBlack: {
      backgroundColor: '#111',
      color: '#ffffff',
      [theme.breakpoints.down('xs')]: {
        '&:hover': {
          backgroundColor: '#111',
          color: '#ffffff',
        },
      },
    },
    fabNext: {
      width: '40px',
      height: '40px',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      height: '100vh',
      width: '100%',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        background: '#111',
        opacity: '0',
        pointerEvents: 'none',
        transition: 'opacity 0.2s ease-in',
        zIndex: '1',
      },
      '&.darkened:before': {
        opacity: '0.4',
      },
    },
    textField: {
      backgroundColor: theme.palette.background.default,
      fontSize: '20px',
      borderRadius: '50px',
      margin: '0',
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '25px',
      paddingRight: '25px',
      width: '400px',
      '& button.MuiButtonBase-root': {
        position: 'static',
      },
      '&.error': {
        border: '1px solid red',
      },
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 40px)',
      },
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    nextButton: {
      backgroundColor: '#111',
      position: 'absolute',
      top: '5px',
      right: '5px',
      // transform: 'translateY(0px)',
      [theme.breakpoints.down('xs')]: {
        right: '5px',
      },
    },
    joinButton: {
      // backgroundColor: 'rgba(0,0,0,0)',
      margin: '5px',
      position: 'relative',
      transition: 'transform 0.2s ease-in',
      [theme.breakpoints.down('md')]: {
        height: '50px',
        width: '50px',
        margin: '5px 7.5px',
        backgroundColor: '#fff',
        color: '#111',
      },
      '&:hover': {
        transform: 'scale(1.1)',
      },
      '&.device:hover, &.device:focus': {
        backgroundColor: '#fff',
        color: '#111',
      },
    },
    arrowIcon: {
      width: '18px',
    },
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#111',
      color: '#fff',
      padding: '15px 20px 13px',
      borderRadius: '30px',
      fontWeight: 500,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        padding: '12px 20px 11px',
      },
    },
    tooltipArrow: {
      color: '#111',
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    textInput: {
      color: '#111',
      fontSize: '15px',
      fontWeight: 500,
      //textTransform: 'uppercase',
      padding: 0,
      width: '200px',
      '&::placeholder': {
        color: 'black',
        opacity: '1',
      },
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
    controls: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%, 30px)',
      bottom: '80px',
      zIndex: 1,
      opacity: 1,
      visibility: 'visible',
      maxWidth: 'min-content',
      [theme.breakpoints.down('md')]: {
        bottom: 0,
        height: '106px',
        transform: 'translate(50%)',
        alignItems: 'center',
      },
    },
    infoContainer: {
      width: '100vw',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {},
      [theme.breakpoints.down('xs')]: {
        display: 'none',
        height: 'auto',
        // display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        paddingBottom: '10px',
        position: 'absolute',
        bottom: '0',
        top: 'auto',
      },
    },
    name: {
      fontSize: 36,
      fontWeight: 300,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      padding: '30px 48px',
      bottom: '48px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        bottom: '0',
        padding: '0',
        position: 'relative',
      },
    },
    city: {
      fontSize: 18,
      fontWeight: 300,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      padding: '30px 48px',
      bottom: '0',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        right: '0',
        margin: '0',
        position: 'relative',
        padding: '0',
        paddingLeft: '20px',
      },
    },
    passwordErrorMessage: {
      position: 'relative',
      bottom: '-20px',
      backgroundColor: '#FF6666',
      textAlign: 'center',
      width: '80%',
      margin: 'auto',
      borderRadius: '30px',
      color: '#FFF',
      fontVariant: 'small-caps',
      fontWeight: 'bold',
    },
    localVideo: {
      width: '100vw',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'relative',
      },
    },
    error: {
      display: 'block',
      position: 'absolute',
      top: 'calc(50% + 30px)',
      left: '50%',
      transform: 'translateX(-50%)',
      color: 'red',
      fontFamily: 'NB Akademie Std,Source Sans Pro,Arial,sans-serif',
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: 500,
    }
  })
);

const formDataFromArray = (labels) => {
  let formFields = [];
  if (labels?.length > 0) {
    formFields = labels.map(l => ({
      name: l,
      value: '',
    }));
  }
  if (process.env.REACT_APP_FORM_EMAIL === 'true') formFields.push({ name: 'EMAIL', value: '' });
  if (process.env.REACT_APP_AUTH_ENABLED === 'true') formFields.unshift({ name: 'PASSWORD', value: '' });
  return formFields
};

let defaultFormData;
if (window.location.pathname === '/SS22') (defaultFormData = process.env.REACT_APP_FORM_FIELDS_PRESS?.length > 0 && process.env.REACT_APP_FORM_FIELDS_PRESS.split('|'));
else if (window.location.pathname === '/SS22Internal') (defaultFormData = process.env.REACT_APP_FORM_FIELDS_ASSOCIATES?.length > 0 && process.env.REACT_APP_FORM_FIELDS_ASSOCIATES.split('|'));
else defaultFormData = process.env.REACT_APP_FORM_FIELDS?.length > 0 && process.env.REACT_APP_FORM_FIELDS.split('|')

const stateData = formDataFromArray(defaultFormData);

export default function StreamParticipantForm(props) {
  const classes = useStyles();
  const [validated, setValidated] = useState(process.env.REACT_APP_AUTH_ENABLED === 'true' ? false : true);
  const [step, setStep] = useState(0);
  const [formFields, setFormFields] = useState(stateData);
  const [blinkingReady, setBlinkingReady] = useState(false);
  const [blink, setBlink] = useState(false);
  const [enteringStream, setEnteringStream] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [showPassword, setShowPassword] = useState(process.env.REACT_APP_AUTH_ENABLED === 'true');
  const [error, setError] = useState();

  const formRef = useRef();

  const { connect: playerConnect, vibeConfig } = usePlayerState();
  const { initializeChat } = useChatContext();

  const { URLRoomName } = useParams();
  /* const isFormDisabled =
    isAcquiringLocalTracks || isConnecting || (validated && !formFields[step]?.value) || isFetching; */
  //const noiseGif = useNoiseGif();

  const { invalidLocation } = props;

  /*  const handleJoinChat = () => {
      getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  }; */

  const handleSubmit = async (event) => {

    if (event) event.preventDefault();

    const btn = document.getElementById('submit');
    if (btn) btn.disabled = true;

    setEnteringStream(true);

    let formData = new FormData();

    formFields.forEach(field => {
      if (field.name !== 'PASSWORD') formData.append(field.name, field.value);
    })

    try {
      // Send form values to usebasin
      await axios({
        method: 'POST',
        baseURL: process.env.REACT_APP_FORM_URL,
        data: formData,
      }).then(() => {
        if (process.env.REACT_APP_CHAT_ROOM_NAME) {
          // Connect to chat conversation
          let formFieldValues = _.map(formFields, (field => field.value))
          const chat = {
            identity: formFieldValues.join('-').replace('@', '-'),
            conversationName: process.env.REACT_APP_CHAT_ROOM_NAME,
            participantName: formFields[0].value || '',
            options: {
              ask: true,
              host: false,
              public: Boolean(process.env.REACT_APP_PUBLIC_VIBECHAT) || Boolean(vibeConfig?.vibechat?.public_chat)
            },
          };
          initializeChat(chat);
        }
      });
  
      // let the noise gif show for 2 seconds
      
    } catch (err) {
      console.log("error", err);
    }
    
    playerConnect(formFields);
  };


  const fieldChanged = (e) => {
    let { target } = e;
    const fieldName = target.id;
    const { value } = target;

    let newFields = [...formFields];
    if (Boolean(newFields.find(field => field.name.replace(' ', '-') === fieldName))) {
      newFields.find(field => field.name.replace(' ', '-') === fieldName).value = value;
    }
    setFormFields(newFields);
  };

  let customForm = formFields.map((formField, index) => {
    const fieldName = formField.name.replace(' ', '-');
    return (
      <>
        <TextField
          key={index}
          autoFocus
          id={fieldName}
          type={formField.name === 'PASSWORD' && showPassword ? 'password' : 'text'}
          autoComplete={formField.name.toLowerCase().includes('name') ? 'name' : formField.name}
          placeholder={formField.name}
          className={clsx(classes.textField, {
            'error': !!error
          })}
          margin="dense"
          inputProps={{
            maxLength: 25,
          }}
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.textInput,
            },
            startAdornment: (
              <>
                {formField.name === 'PASSWORD' && (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="start"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                      {error && (
                        <span className={classes.error}>{error}</span>
                      )}
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
          onChange={fieldChanged}
        >
        </TextField>
        {error && (
          <span className={classes.error}>{error}</span>
        )}
      </>
    );
  });

  const maxSteps = customForm.length;

  useEffect(() => {
    let mounted = true;
    // console.log('change in step', step);
    let timeout;
    let interval;

    if (step === maxSteps) {
      // create interval that sets the blinking effect on

      const addInterval = () => {
        timeout = setTimeout(() => {
          setBlinkingReady(true);
          setBlink(true);
          if (!mounted) return;
          interval = setInterval(() => {
            setBlink(false);
            setTimeout(() => {
              setBlink(true);
            }, 800);
          }, 2000);
        }, 4000);
      };

      addInterval();
    }

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
      mounted = false;
    };
  }, [step, blinkingReady, blink, maxSteps]);

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    };

    const handleKeyup = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        handleStepChange();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('keyup', handleKeyup);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('keyup', handleKeyup);
    };
  });

  useEffect(() => {
    const params = {
      instance: 'devvibevideo',
      channel: decodeURIComponent(URLRoomName).split('|')[1],
      show: decodeURIComponent(URLRoomName).split('|')[2],
      session: decodeURIComponent(URLRoomName).split('|')[3],
    };

    //const urlParams = new window.URLSearchParams(params);
    /* 
    nodeConfig.get(`/get-vibe-config?${urlParams}`)
      .then(response => response.json())
      .then(jsondata => {
        console.log("jsondata", jsondata);
      })
      .catch(e => {
        console.log(e);
      });
   */
  // eslint-disable-next-line
  }, []);

  const handleStepChange = async () => {
    // steps start at 0 -- step = customForm.length - 1 is actually the last form field
    // step === maxSteps is preview

    if (step === formFields.length) {
      setShowFields(false);
      return;
    }

    if (step < maxSteps) {
      //console.log("entro");

      if (formFields[step].value.length < 1) {
        setError('empty field');
        return;
      }

      if (formFields[step].name === 'EMAIL') {
        let validateEmail = /^[a-zA-Z0-9 ._-]{1,30}@[a-zA-Z0-9 ._-]{1,30}$/i
        const isEmailCorrect = validateEmail.test(formFields[step].value);

        if (isEmailCorrect) {
          setError();
        } else {
          setError('enter a valid email address');
          return;
        }
      }

      if (formFields[step].name === 'PASSWORD') {
        if (formFields[step].value === process.env.REACT_APP_NODE_PASSWORD) {
          setError();
          setValidated(true);
        }
        else {
          setError('invalid password');
          return;
        }
      }

      if (step < maxSteps - 1) {
        setStep(s => s + 1);
      }

      if (step === maxSteps - 1) {
        setStep(s => s + 1);
        handleSubmit(null);
      }
      return;
    }
  };

  useEffect(() => {
    if (vibeConfig?.input_fields) {
      const differentForm =
        vibeConfig.input_fields.length !== defaultFormData.length ||
        vibeConfig.input_fields.find(label => !defaultFormData.includes(label));
      if (differentForm) {
        //console.log(vibeConfig);
        setFormFields(formDataFromArray(vibeConfig?.input_fields));
      }
    }
    // eslint-disable-next-line
  }, [vibeConfig]);

  let inputElement = customForm[step];
  
  return (
    <>
      <FullScreenAppBackground blurred={!validated}>
        <>
          <form 
            className={clsx(classes.form, { darkened: validated && blinkingReady }, {
              'error': !!error
            })} 
            ref={formRef}
            onSubmit={handleSubmit}
          >
            {validated && step === maxSteps ? (
              <div className={classes.localVideo}>
                <div className={classes.infoContainer}>
                  <h4 className={classes.name}>{formFields[0]?.value}</h4>
                  <h4 className={classes.city}>{formFields[1]?.value}</h4>
                </div>
                <div className={classes.controls}>
                  <Tooltip
                    classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow, touch: classes.tooltipTouch }}
                    arrow
                    title={enteringStream ? 'JOINING...' : 'JOIN'}
                    onOpen={() => setBlink(true)}
                    onClose={() => {
                      setBlink(false);
                    }}
                    open={blink}
                  >
                    <div>
                      <Fab
                        type="submit"
                        id="submit"
                        className={`${classes.joinButton} ${classes.fab} ${classes.fabBlack}`}
                      >
                        <IconArrowRight className={classes.arrowIcon} />
                      </Fab>
                    </div>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <>
                <Toolbar
                  style={{
                    opacity: '1',
                    transition: 'opacity 0.3s ease-in 2s',
                  }}
                  className={classes.toolbar}
                >
                  <div 
                    style={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      position: 'relative',
                      width: '100%',
                    }}
                    >
                    <div
                      style={{ 
                        display: 'flex', 
                        alignItems: 'center' ,
                        width: '100%',
                      }}
                    >
                      <>
                        <CSSTransition
                          key={step}
                          classNames="fade"
                          timeout={{
                            exit: 300,
                            enter: 800,
                          }}
                        >
                          <div style={{ width: '100%', display: 'flex' }}>
                            {invalidLocation ? (
                              <TextField
                                disabled
                                placeholder={'Invalid URL'}
                                className={classes.textField}
                                margin="dense"
                                InputProps={{
                                  disableUnderline: true,
                                  classes: {
                                    input: classes.textInput,
                                  },
                                }}
                              ></TextField>
                            ) : (
                              inputElement
                            )}
                            {!invalidLocation && (
                              <Fab
                                onClick={handleStepChange}
                                classes={{ root: classes.fab, disabled: classes.disabledFab }}
                                className={classes.fabBlack + ' ' + classes.fabNext + ' ' + classes.nextButton}
                                disabled={false}
                              >
                                <IconArrowRight className={classes.arrowIcon} />
                              </Fab>
                            )}
                          </div>
                        </CSSTransition>
                      </>
                    </div>
                  </div>
                </Toolbar>
              </>
            )}
          </form>
        </>
      </FullScreenAppBackground>
    </>
  );
}
