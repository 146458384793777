import React, { useEffect } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

import IconSpeaker from '../Icons/IconSpeaker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100px',
      marginRight: '10px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      background: '#111',
      borderRadius: '20px',
      height: '40px',
      padding: '0 15px',
      [theme.breakpoints.down('sm')]: {
        width: '50px',
        height: '50px',
        borderRadius: '100%',
        padding: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    sliderRoot: {
      top: '-1px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    track: {
      height: '4px',
      background: '#FFF',
    },
    rail: {
      height: '4px',
      background: '#FFF',
    },
    thumb: {
      display: 'none',
    },
    speaker: {
      width: '12px',
      marginRight: '7px',
      color: '#FFF',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        width: '25px',
      },
    },
    desktop: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    muteVideo: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none',
        minWidth: '0',
        width: '50px',
        height: '50px',
        lineHeight: '50px',
        borderRadius: '100%',
        background: '#111',
        color: '#ffffff',
        '&.red': {
          background: '#FF6666',
        },
      },
    },
  })
);

export default function VolumeBar({value, updateVolume, isMuted} : { value: number; updateVolume: (v : number) => void; isMuted: boolean; }) {
  const classes = useStyles();

  const handleChange = (e: any, newValue: any) => {
    if (newValue === value) return;
    updateVolume(newValue);
  };

  const handleToggleMuteVideo = () => {
    const muted = isMuted || value === 0;
    const volume = muted ? 100 : 0;
    updateVolume(volume);
  };


  return (
    <div className={clsx('vibe-volume-bar', classes.root)}>
      <IconSpeaker className={clsx('vibe-volume-bar-speaker-icon', classes.speaker, classes.desktop)}></IconSpeaker>
      <button className={clsx(classes.muteVideo, { red: value === 0 || isMuted })} onClick={() => handleToggleMuteVideo()}>
        <IconSpeaker className={clsx('vibe-volume-bar-speaker-icon', classes.speaker)}></IconSpeaker>
      </button>
      <Slider
        classes={{ root: classes.sliderRoot, track: classes.track, rail: classes.rail, thumb: classes.thumb }}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="off"
        aria-labelledby="discrete-slider"
        // step={1}
        min={0}
        max={100}
      />
    </div>
  );
}
