import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ReactHlsPlayer from 'react-hls-player';
//import { SyncClient } from 'twilio-sync';
import mux from 'mux-embed';
import PlayerMenuBar from './PlayerMenuBar';
import { usePlayerState } from './PlayerProvider';
import StreamLobby from './StreamLobby';
import StreamReconnect from './StreamReconnect';
import OnDemandPlayer from './OnDemandPlayer/OnDemandPlayer';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      background: 'black',
      height: '100%',
      width: '100%',
      position: 'relative',
      paddingBottom: '0px', // Leave some space for the footer
    },
    video: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      objectFit: 'contain',
      backgroundColor: '#fff',
    },
  })
);

export default function Player() {
  const classes = useStyles();
  const videoElRef = useRef(null);
  //const [syncToken, setSyncToken] = useState();
  const { URLRoomName } = useParams();
  const { playerState, viewerInfo, stream } = usePlayerState();
  // gets a token for using twilio sync
  /* useEffect(() => {
    const getSyncToken = async () => {
      const isSyncOn = true;
      if (isSyncOn) {
        const syncToken = await axios({
          method: 'GET',
          url: `/sync-token?identity=pedro`,
        });

        console.log('syncToken', syncToken.data);
        setSyncToken(syncToken.data.token);
      }
    };
    getSyncToken();
  }, []);

  // connects to room-status sync and listens for incoming msgs
  /*  useEffect(() => {
    if (syncStream) {
      syncStream.on('messagePublished', event => {
        console.log('Received a "messagePublished" event:', event);
      });
    }
  }, [syncStream]); 

  useEffect(() => {
    if (syncToken) {
      const syncClient = new SyncClient(`${syncToken}`);

      syncClient
        .stream('room-status')
        .then(stream => {
          console.log('Successfully opened a message stream. SID:', stream.sid);
          stream.on('messagePublished', event => {
            console.log('Received a "messagePublished" event:', event);
          });
        })
        .catch(error => {
          console.error('Unexpected error', error);
        });
    }
  }, [syncToken]); */

  /*  useEffect(() => {
    const fireOnVideoEnd = () => {
      // Do some stuff when the video ends
      console.log('video ended');
    };
    const fireOnVideoStart = () => {
      // Do some stuff when the video starts/resumes playing
      console.log('video started');
    };

    const videoRef = videoElRef.current;

    videoRef.addEventListener('play', fireOnVideoStart);
    videoRef.addEventListener('ended', fireOnVideoEnd);

    return () => {
      videoRef.removeEventListener('play', fireOnVideoStart);
      videoRef.removeEventListener('ended', fireOnVideoEnd);
    };
  }, []);
 */

  useEffect(() => {
    if (videoElRef.current && !!viewerInfo && viewerInfo.length > 0) {
      const initTime = Date.now();
      mux.monitor(videoElRef.current, {
        debug: false,
        data: {
          env_key: process.env.REACT_APP_MUX_ENV_KEY, // required
          // Metadata fields
          video_title: decodeURIComponent(URLRoomName),
          player_name: 'Stream player', // any arbitrary string you want to use to identify this player
          player_init_time: initTime,
          viewer_first_input_name: viewerInfo[0].name,
          viewer_first_input_value: viewerInfo[0].value,
        },
      });
    }
    // eslint-disable-next-line
  }, [videoElRef]);

  useEffect(() => {
    if (playerState === 'reconnecting') videoElRef.current && videoElRef.current.pause();
    if (playerState === 'connected') videoElRef.current && videoElRef.current.play();
  }, [playerState])

  //console.log("playerState", playerState);
  if (Boolean(process.env.REACT_APP_VIBE_ON_DEMAND)) {
    return (
      <div style={{ height: '100%', overflow: 'hidden', maxHeight: '100vh' }}>
        <div className={classes.container} id="mainParticipantContainer">
          <OnDemandPlayer />
        </div>
      </div>
    )
  }
  return (
    <>
      {playerState === 'connecting' && <StreamLobby />}
      {/* {true && <StreamReconnect />} */}
      {(playerState === 'reconnecting' || playerState === 'disconnecting') && <StreamReconnect />}
      {/* {true && ( */}
      {(playerState === 'connected' || playerState === 'reconnecting' || playerState === 'disconnecting') && (!!stream || process.env.REACT_APP_TEST_PLAYER) && (
        <div style={{ height: '100%' }}>
          <div className={classes.container} id="video-stream-div">
            <ReactHlsPlayer
              src={`${process.env.REACT_APP_TEST_PLAYER 
                ? process.env.REACT_APP_SOURCE_HORIZONTAL
                : 'https://stream.mux.com/' + stream.playback_ids[0].id + '.m3u8'
              }`}
              autoPlay={true}
              controls={false}
              playerRef={videoElRef}
              width="100%"
              height="auto"
              className={classes.video}
            />
          </div>
          <PlayerMenuBar roomName={URLRoomName} />
        </div>
      )}
    </>
  );
}
