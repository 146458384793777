import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconSpeaker from './Icons/IconSpeaker';


const useStyles = makeStyles((theme) =>
  createStyles({
    iconSpeaker: {
      color: '#ffffff',
      width: '20px',
      height: '20px',
      marginRight: '10px',
    },
    unmuteBanner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      color: '#ffffff',
      background: 'rgba(17,17,17,0.5)',
      display: 'flex',
      alignItems: 'flex-start',
      padding: '15px 22px',
      textTransform: 'uppercase',
      borderRadius: '60px',
      fontWeight: 600,
      fontSize: '14px',
      whiteSpace: 'nowrap',
      zIndex: 1,
    },
    span: {
      position: 'relative',
      top: '2px',
    },
  })
);

export default function TapForSound() {
  const classes = useStyles();
  return (
    <article className={classes.unmuteBanner}>
      <IconSpeaker className={classes.iconSpeaker}></IconSpeaker>
      <span className={classes.span}>tap for sound</span>
    </article>
  );
}
