import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

export default function useHeight() {
  const [height, setHeight] = useState(window.innerHeight * (window.visualViewport?.scale || 1));
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const onResize = () => {
      setHeight(window.innerHeight * (window.visualViewport?.scale || 1));
    };

    const handleScroll = () => {
      if (window.screen.width < 521) {
        setScroll(window.scrollY);
      }
    }

    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', throttle(handleScroll, 200));
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', throttle(handleScroll, 200));
    };
  });

  return { height: `${height}px`, scroll };
}
