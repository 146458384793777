import React from 'react';

export default function IconRemoveMessage() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#CCCCCC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46449 6.46447C6.07397 6.85499 6.07397 7.48815 6.46449 7.87868L8.58581 10L6.46449 12.1213C6.07396 12.5118 6.07396 13.145 6.46449 13.5355C6.85501 13.9261 7.48818 13.9261 7.8787 13.5355L10 11.4142L12.1213 13.5355C12.5119 13.9261 13.145 13.9261 13.5356 13.5355C13.9261 13.145 13.9261 12.5118 13.5356 12.1213L11.4142 10L13.5356 7.87868C13.9261 7.48815 13.9261 6.85499 13.5356 6.46447C13.145 6.07394 12.5119 6.07394 12.1213 6.46447L10 8.58578L7.8787 6.46447C7.48818 6.07394 6.85501 6.07394 6.46449 6.46447Z"
        fill="white"
      />
    </svg>
  );
}
