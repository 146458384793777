import axios from "axios";

export const encodedAuth = btoa(`${process.env.REACT_APP_NODE_USERNAME}:${process.env.REACT_APP_NODE_PASSWORD}`);

const nodeConfig = axios.create({
  baseURL: '/',
});

nodeConfig.defaults.headers.common["Authorization"] = `Basic ${encodedAuth}`;

export default nodeConfig;