import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    typingLoader: {
      textAlign: 'center',

      '& span': {
        margin: '0 1px',
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '10px',
        height: '10px',
        background: '#999999',
        borderRadius: '15px',
        animationName: '$typing',
        animationDuration: '0.8s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
      },
      '& span:nth-of-type(2)': {
        animationDelay: '0.2s',
      },
      '& span:nth-of-type(3)': {
        animationDelay: '0.6s',
      },
    },
    '@keyframes typing': {
      '0%': {
        opacity: 0.8,
      },
      '50%': {
        opacity: 0.5,
      },
      '100%': {
        opacity: 0.2,
      },
    },
  })
);

export default function TypingLoader() {
  const classes = useStyles();
  return (
    <div className={classes.typingLoader}>
      <span />
      <span />
      <span />
    </div>
  );
}
