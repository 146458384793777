import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Message } from '@twilio/conversations/lib/message';
import TextMessage from '../ChatWindow/MessageList/TextMessage/TextMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newChatContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      fontSize: '15px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      letterSpacing: '-0.333333px',
      width: 'fit-content',
      minWidth: '300px',
      maxWidth: '400px',
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset',
        maxWidth: 'unset',
        width: '100%',
      },
    },
  })
);

export default function NewChatAlert({ message, author }: { message: Message; author: string }) {
  const classes = useStyles();

  return (
    <div className={classes.newChatContainer}>
      {message.type === 'text' && <TextMessage body={message.body} author={author} />}
    </div>
  );
}
