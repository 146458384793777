import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      postion: 'relative',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      background: 'white',
      height: '100%',
      width: '100vw',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
      },
    },
    childComponents: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100vh',
    },
    mobBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    // prada: {
    //   backgroundImage: `url(${PradaBackgroundImage})`,
    // },
    fallbackImg: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      objectFit: 'cover',
      backgroundPosition: 'center center',
    },
    videoBg_video: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      '&.password-blurred': {
        filter: 'blur(55px)',
      },
      '&.mobile-only': {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
          display: 'block',
        },
      },
      '&.desktop-only': {
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
  })
);

export default function FullScreenAppBackground({ blurred, children }) {
  const classes = useStyles();

  return (
    <>
      {/* Fallback image (Desktop or Mobile) */}
      <img
        className={clsx(classes.fallbackImg)}
        style={{
          backgroundImage: `url(${window.screen.width > 768 ? `${process.env.REACT_APP_FALLBACK_IMG_DESKTOP}` : `${process.env.REACT_APP_FALLBACK_IMG_MOBILE}`})`,
        }}
        alt=""
      />

      {/* Background Image (Desktop or Mobile) */}
      {(process.env.REACT_APP_SOURCE_IMG_DESKTOP || process.env.REACT_APP_SOURCE_IMG_MOBILE) 
        && (!process.env.REACT_APP_SOURCE_VID_DESKTOP && !process.env.REACT_APP_SOURCE_VID_MOBILE) 
        && (
        <div
          className={clsx(classes.mobBackground, blurred && 'password-blurred')}
          style={{
            backgroundImage: `url(${window.screen.width > 768 ? `${process.env.REACT_APP_SOURCE_IMG_DESKTOP}` : `${process.env.REACT_APP_SOURCE_IMG_MOBILE}`})`,
            filter: blurred ? 'blur(55px)' : '',
          }}
        ></div>
      )}
      {/* DESKTOP VIDEO BACKGROUND */}
      {process.env.REACT_APP_SOURCE_VID_DESKTOP && window.screen.width > 768 && (
        <video
          autoPlay
          playsInline
          loop
          muted
          className={clsx(classes.videoBg_video, {
            'desktop-only': process.env.REACT_APP_SOURCE_VID_MOBILE,
          })}
          src={process.env.REACT_APP_SOURCE_VID_DESKTOP}
        ></video>
      )}

      {/* MOBILE VIDEO BACKGROUND */}
      {process.env.REACT_APP_SOURCE_VID_MOBILE && window.screen.width < 769 && (
        <video
          autoPlay
          playsInline
          loop
          muted
          className={clsx(classes.videoBg_video, 'mobile-only', blurred && 'password-blurred')}
          src={process.env.REACT_APP_SOURCE_VID_DESKTOP}
        ></video>
      )}
      <div className={classes.childComponents}>{children}</div>
    </>
  );
}
