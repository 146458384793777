import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    svgIcon: {
     
    },
    rect: {
      color: 'white',
      opacity: '0',
      animationName: '$svgAnimation',
      animationDuration: '1.2s',
      animationIterationCount: 'infinite',
      transition: '200ms'
    },
    'r-1': {
      animationDelay: '0s',
    },
    'r-2': {
      animationDelay: '0.1s',
    },
    'r-3': {
      animationDelay: '0.2s',
    },
    'r-4': {
      animationDelay: '0.3s',
    },
    'r-5': {
      animationDelay: '0.4s',
    },
    'r-6': {
      animationDelay: '0.5s',
    },
    'r-7': {
      animationDelay: '0.6s',
    },
    'r-8': {
      animationDelay: '0.7s',
    },
    'r-9': {
      animationDelay: '0.8s',
    },
    'r-10': {
      animationDelay: '0.9s',
    },
    'r-11': {
      animationDelay: '1s',
    },
    'r-12': {
      animationDelay: '1.1s',
    },
    '@keyframes svgAnimation': {
      '0%': {
        opacity: 1,
      },
      '8.33%': {
        opacity: 0.88,
      },
      '16.66%': {
        opacity: 0.8,
      },
      '25%': {
        opacity: 0.72,
      },
      '33.33%': {
        opacity: 0.64,
      },
      '41.66%': {
        opacity: 0.56,
      },
      '50%': {
        opacity: 0.48,
      },
      '58.33%': {
        opacity: 0.4,
      },
      '66.66%': {
        opacity: 0.32,
      },
      '75%': {
        opacity: 0.24,
      },
      '83.33%': {
        opacity: 0.16,
      },
      '91.66%': {
        opacity: 0.08,
      },
      '100%': {
        opacity: 0,
      },
    }
  })
);


function IconSpinner(props) {

  const classes = useStyles();

  return (
    <SvgIcon className={classes.svgIcon} viewBox="0 0 108 108" fill="none" {...props}>
      <rect className={clsx(classes.rect, classes['r-1'])} x="48" width="12" height="30" rx="6" />
      <rect className={clsx(classes.rect, classes['r-7'])} x="48" y="78" width="12" height="30" rx="6" />
      <rect className={clsx(classes.rect, classes['r-12'])} x="21.8037" y="10.2344" width="12" height="30" rx="6" transform="rotate(-30 21.8037 10.2344)" />
      <rect className={clsx(classes.rect, classes['r-6'])} x="60.8037" y="77.7842" width="12" height="30" rx="6" transform="rotate(-30 60.8037 77.7842)" />
      <rect className={clsx(classes.rect, classes['r-11'])} x="4.23438" y="32.1958" width="12" height="30" rx="6" transform="rotate(-60 4.23438 32.1958)" />
      <rect className={clsx(classes.rect, classes['r-5'])} x="71.7842" y="71.1958" width="12" height="30" rx="6" transform="rotate(-60 71.7842 71.1958)" />
      <rect className={clsx(classes.rect, classes['r-10'])} y="59.9995" width="12" height="30" rx="6" transform="rotate(-90 0 59.9995)" />
      <rect className={clsx(classes.rect, classes['r-4'])} x="78" y="59.9995" width="12" height="30" rx="6" transform="rotate(-90 78 59.9995)" />
      <rect className={clsx(classes.rect, classes['r-9'])} x="10.2344" y="86.1953" width="12" height="30" rx="6" transform="rotate(-120 10.2344 86.1953)" />
      <rect className={clsx(classes.rect, classes['r-3'])} x="77.7842" y="47.1953" width="12" height="30" rx="6" transform="rotate(-120 77.7842 47.1953)" />
      <rect className={clsx(classes.rect, classes['r-8'])} x="32.1958" y="103.765" width="12" height="30" rx="6" transform="rotate(-150 32.1958 103.765)" />
      <rect className={clsx(classes.rect, classes['r-2'])} x="71.1958" y="36.2148" width="12" height="30" rx="6" transform="rotate(-150 71.1958 36.2148)" />
    </SvgIcon>
  );
}

export default IconSpinner;
