import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconCopy(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 17 19" {...props}>
      <rect x="6" y="4" width="10" height="14" rx="1" fill="#CCCCCC" stroke="#111111" strokeWidth="2" />
      <rect x="1" y="1" width="10" height="14" rx="1" fill="white" stroke="#111111" strokeWidth="2" />
    </SvgIcon>
  );
}

export default IconCopy;
