import { styled, makeStyles, createStyles } from '@material-ui/core/styles/';
import Logo from './Logo';
import clsx from 'clsx';
import Player from './Player';
import { usePlayerState } from './PlayerProvider';
import ChatWindow from './ChatWindow/ChatWindow';
import useHeight from './hooks/useHeight/useHeight';
import StreamParticipantForm from './components/StreamParticipantForm/StreamParticipantForm';
import { useEffect, useRef } from 'react';

const RoomContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr',
});

const LocalContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }) => ({
  overflow: 'hidden',
  // paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  // background: 'black',
  // zIndex: 1100,
  [theme.breakpoints.down('sm')]: {
    // paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '100vh',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100vh',
      },
    },
  })
);


function StreamInner() {

  const classes = useStyles();
  const { playerState } = usePlayerState();

  //console.log("playerState", playerState);
  const Container = true ? LocalContainer : RoomContainer;

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Container
      style={{ minHeight: '-webkit-fill-available' }}
    >
      <Logo source={playerState === 'connected' ? process.env.REACT_APP_SOURCE_LOGO_STREAM : process.env.REACT_APP_SOURCE_LOGO_FORM} />
      <Main 
        style={{ top: 0 }}
        className={clsx(classes.main)}>
        {playerState === 'disconnected' ? <StreamParticipantForm invalidLocation={false} /> : <Player />}
        {/* {false ? <StreamParticipantForm invaflidLocation={false} /> : <Player />} */}
      </Main>
      {playerState === 'connected' && <ChatWindow />}
    </Container>
  )
}

export default StreamInner;