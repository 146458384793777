import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Preroll from './Preroll';

// Participants who view waiting room list instead of preroll:
// Host, Studio Host, Producer

const useStyles = makeStyles((theme) =>
  createStyles({
    fullscreen: {
      position: 'relative',
      height: '100%',
      width: '100%',
      zIndex: 20,
      background: '#FFF',
    },
    container: {
      width: '600px',
      minHeight: '400px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    paper: {
      maxWidth: 'none',
      [theme.breakpoints.down('xs')]: {
        // margin: '16px',
        margin: 20,
        width: '100%',
      },
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px 25px',
      justifyContent: 'flex-end',
      // justifyContent: 'space-between',
      '& button': {
        cursor: 'pointer',
        backgroundColor: '#111',
        color: '#EEE',
        border: 'none',
        borderRadius: '30px',
        fontSize: '12px',
        lineHeight: '40px',
        letterSpacing: '-0.02em',
        fontWeight: 600,
        padding: '0 20px',
      },
    },
    startSession: {
      '&:hover': {
        backgroundColor: '#3333FF',
      },
    },
  })
);

export default function StreamLobby() {
  const classes = useStyles();

  return (
    <div className={classes.fullscreen}>
      <Preroll />
    </div>
  );
}
