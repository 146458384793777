import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconCheckmark(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <rect x="9" y="20.8284" width="4" height="10.9753" rx="2" transform="rotate(-45 9 20.8284)" fill="#999999" />
      <rect x="14" y="25.7858" width="19.5161" height="4" rx="2" transform="rotate(-45 14 25.7858)" fill="#999999" />
    </SvgIcon>
  );
}

export default IconCheckmark;
