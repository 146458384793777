import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import StreamingApp from './StreamingApp';
import { MuiThemeProvider } from '@material-ui/core/styles/';
import theme from './theme';
import { useState } from 'react';
import NotFound from './components/NotFound/NotFound';


function App() {
  const [currentTheme, setCurrentTheme] = useState(theme);
  return (
    <>
      <MuiThemeProvider theme={currentTheme}>
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<StreamingApp />} />
            <Route exact path='/SS22' element={<StreamingApp />} />
            <Route exact path='/SS22Internal' element={<StreamingApp />}/>
            <Route exact path='*' element={<NotFound />}/>
          </Routes>
        </BrowserRouter>
      </MuiThemeProvider>
    </>
  );
}

export default App;
