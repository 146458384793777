import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosResponse } from 'axios';
import { Link } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import clsx from 'clsx';
import linkify from 'linkify-it';
import _ from 'lodash';
import IconSpinner from '../../../Icons/IconSpinner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    container: {
      '&:first-child': {
        marginTop: '10px',
      },
      '&:last-child': {
        marginBottom: '10px',
      },
      width: '360px',
      minHeight: '360px',
      backgroundColor: '#EEEEEE',
      borderRadius: '20px',
      // fontFamily: 'NB Akademie Std',
      fontWeight: 600,
      letterSpacing: '-0.333333px',
      fontSize: '15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 76px)',
      },
    },
    noImage: {
      minHeight: '60px',
      padding: '3px 0',
      borderRadius: '10px',
    },
    imageContainer: {
      width: '100%',
      height: '360px',
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vw - 76px)',
      },
    },
    image: {
      objectFit: 'cover',
      opacity: 1,
      transition: 'opacity 0.3s',
    },
    infoContainer: {
      padding: '12px 15px',
      lineHeight: 1.2,
    },
    title: {
      fontSize: '15px',
      lineHeight: '18px',
      color: '#111111',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: 2,
      '-webkit-line-clamp': 2 /* number of lines to show */,
      '-webkit-box-orient': 'vertical',
    },
    hostname: {
      color: '#999999',
      fontSize: '12px',
      lineHeight: '14.4px',
    },
    errorLink: {
      textDecoration: 'underline',
    },
    iconSpinnerContainer: {
      width: '60px',
      height: '60px',
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconSpinner: {
      width: '27px',
      height: '27px',
    },
    hideSpinner: {
      display: 'none',
    },
    hiddenImage: {
      display: 'none',
    },
  })
);

const isValidLink = (url: string) => {
  return !_.isNil(url) && linkify().test(url);
};

export default function LinkPreview({ url }: { url: string }) {
  const classes = useStyles();
  const { linkCache, setLinkCache, getLinkPreview } = useChatContext();
  const [metadata, setMetadata] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [isImgLoaded, setImgLoaded] = useState(false);
  const [isImgError, setImgError] = useState(false);

  useEffect(() => {
    if (linkCache.has(url)) {
      setMetadata(linkCache.get(url));
      setIsLoading(false);
    } else {
      // axios({
      //   url: '/get-link-preview',
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json;charset=utf-8',
      //   },
      //   data: { url },
      // })
      getLinkPreview(url)
        .then((data: any) => {
          setMetadata(data);
          setIsLoading(false);
          setLinkCache(new Map(linkCache.set(url, data)));
          if (!data.image && !data.logo) {
            setImgError(true);
          }
        })
        .catch(() => {
          setError(true);
          setIsLoading(false);
        });
    }
  }, []);

  const isThereTitle = metadata?.title || metadata?.retailer;
  const imgAlt = metadata?.description || metadata?.title || metadata?.retailer;
  const isThereImage = !isImgError || isValidLink(metadata?.image?.url) || isValidLink(metadata?.logo?.url);

  if (error)
    return (
      <Link className={classes.errorLink} target="_blank" rel="noreferrer" href={url}>
        {url}
      </Link>
    );
  return (
    <Link className={classes.link} target="_blank" rel="noreferrer" href={url}>
      <div className={clsx(classes.container, { [classes.noImage]: isImgError })}>
        {isThereImage && (
          <div className={classes.imageContainer}>
            {!metadata ? (
              <div className={clsx(classes.iconSpinnerContainer, { [classes.hideSpinner]: isImgLoaded })}>
                <IconSpinner className={classes.iconSpinner} />
              </div>
            ) : (
              <>
                <div className={clsx(classes.iconSpinnerContainer, { [classes.hideSpinner]: isImgLoaded })}>
                  <IconSpinner className={classes.iconSpinner} />
                </div>
                <Fade in={isImgLoaded} timeout={800} appear={!linkCache.has(url)}>
                  <img
                    className={clsx(classes.image, classes.imageContainer, { [classes.hiddenImage]: !isImgLoaded })}
                    src={
                      isValidLink(metadata.image?.url)
                        ? isImgError
                          ? metadata.logo?.url
                          : metadata.image?.url
                        : metadata.logo?.url
                    }                    alt={imgAlt}
                    onLoad={() => setImgLoaded(true)}
                    onError={() => setImgError(true)}
                  />
                </Fade>
              </>
            )}
          </div>
        )}
        <div className={classes.infoContainer}>
          {isThereTitle && (
            <div className={classes.title}>
              {metadata?.title || metadata?.retailer} {metadata?.price && '$' + metadata.price}
            </div>
          )}
          <div className={classes.hostname}>{metadata?.hostname}</div>
        </div>
      </div>
    </Link>
  );
}
