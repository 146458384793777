import React, { useState, useEffect, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import TapForSound from '../TapForSound';
import IconPlay from '../Icons/IconPlay';
import IconPause from '../Icons/IconPause';

import { isMobile, isIOS } from '../utils';
import { Box, Slider, Tooltip } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import _ from 'lodash';
import useIsUserActive from '../hooks/useIsUserActive/useIsUserActive';
import Player from '@vimeo/player';
import VolumeBar from './VolumeBar';
import IconFlip from '../Icons/IconFlip';

import Web3 from 'web3';
import axios from 'axios';
import Onboard from 'bnc-onboard';
import { wallets } from '../utils/wallets';

let web3;

declare var Vimeo: any;

declare global {
  interface Window {
    vimeoPlayer: any;
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: 2,
      background: 'transparent',
      border: 'none',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover, &:focus': {
        outline: 'none',
        border: 'none',
      },
      touchAction: 'none',
    },
    circle: {
      position: 'relative',
      width: '240px',
      height: '240px',
      // left: '50%',
      // top: '50%',
      // transform: 'translateX(-50%), translateY(-50%)',
      backgroundColor: 'rgba(17, 17, 17, 0.2)',
      backdropFilter: 'blur(50px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      transition: 'opacity 0.2s ease-in',
      opacity: '0',
      '&.show': {
        opacity: '1',
      },
      [theme.breakpoints.down('sm')]: {
        width: '60px',
        height: '60px',
      },
    },
    playIcon: {
      width: '88px',
      height: '80px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        width: '40px',
        height: '40px',
      },
    },
    pauseIcon: {
      width: '72px',
      height: '88px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        width: '40px',
        height: '40px',
      },
    },
    flipIcon: {
      width: '110px',
      height: '110px',
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        width: '40px',
        height: '40px',
      },
    },
    titleContainer: {
      position: 'absolute',
      bottom: '40px',
      right: '40px',
      textAlign: 'right',
      height: 'fit-content !important',
      zIndex: 2,
      color: '#FFFFFF',
      fontWeight: 'bold',
      opacity: 1,
      transition: 'opacity 0.2s ease-in, bottom 0.4s 0.7s ease-in',
      '&.hideTitle': {
        opacity: 0,
        bottom: '85px',
        transition: 'opacity 0.4s 0.7s ease-in, bottom 0.4s 0.7s ease-in',
      },
      '&.shiftTitle': {
        bottom: '100px',
        transition: 'bottom 0.2s ease-in',
        [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
          bottom: '80px',
        }
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        marginRight: '25px',
        bottom: '25px',
        left: '25px',
        '&.shiftTitle': {
          bottom: '85px',
        }
      },
    },
    title: {
      // fontSize: '30px',
      fontSize: '21px',
      // lineHeight: '30px',
      lineHeight: '21px',
      letterSpacing: '-0.5px',
      margin: '0 0 10px 0',
    },
    subtitle: {
      // fontSize: '20px',
      fontSize: '14px',
      // lineHeight: '20px',
      lineHeight: '14px',
      letterSpacing: '-0.333333px',
      margin: 0,
      fontStyle: 'italic',
      [theme.breakpoints.down('xs')]: {
        paddingRight: '50px',
      },
    },
    playerNavigationContainer: {
      position: 'absolute',
      zIndex: 2,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '60px !important',
      background: 'rgba(17, 17, 17, 0.1)',
      backdropFilter: 'blur(50px)',
      transition: 'bottom 0.2s ease-in',
      '&.hidePlaybar': {
        bottom: '-100px',
        transition: 'bottom 0.5s 0.7s ease-in',
      }
    },
    playerNavigationInner: {
      padding: '20px 25px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        padding: '20px',
      },
    },
    time: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '20px',
      textAlign: 'center',
      letterSpacing: '-0.2px',
      color: 'rgba(255, 255, 255, 0.5)',
    },
    startTime: {},
    endTime: {},
    chapterContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    timeSlider: {
      margin: '0 20px',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        margin: '0 10px',
      },
    },
    root: {
      height: '100%',
      borderRadius: '2px',
      padding: '6px 0',
      display: 'flex',
      alignItems: 'center',
    },
    rail: {
      height: '4px',
      borderRadius: '2px',
    },
    track: {
      height: '4px',
      borderRadius: '2px',
    },
    thumb: {
      width: 0,
      height: 0,
      boxShadow: 'none',
      '&::after': {
        width: 0,
        height: 0,
        boxShadow: 'none',
      },
      '&:hover': {
        width: 0,
        height: 0,
        boxShadow: 'none',
        transition: 'none',
      },
      '&.MuiSlider-active': {
        width: 0,
        height: 0,
        boxShadow: 'none',
        filter: 'none',
      },
      '&.Mui-focusVisible': {
        width: 0,
        height: 0,
        boxShadow: 'none',
      }

    },
    valueLabel: {
      color: '#FFFFFF',
      fontWeight: 'bold',
      fontSize: '12px',
      letterSpacing: '-0.2px',
      lineHeight: '20px',
      top: '-34px',
      left: 'calc(-50% - 25px)',
      background: 'unset',
      filter: 'drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2))',
      '& span': {
        transform: 'unset',
        borderRadius: '15px',
        width: '60px',
        height: '30px',
        '& span': {
          width: 'auto',
          height: 'auto',
          textAlign: 'center',
        }
      }
    },
    seekingTooltip: {
      background: 'none',
    },
    tooltipPlacementTop: {
      margin: '6px 0',
    },
    seekingContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    seekingThumbnail: {
      width: '240px',
      height: '135px',
      borderRadius: '15px',
      background: 'black',
      '& img': {
        borderRadius: 'inherit',
        width: 'inherit',
        height: 'inherit',
        objectFit: 'cover',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100px',
        height: '56px',
      },
    },
    popper: {
      position: 'absolute',
      top: 0,
    },
    valueLabelSeeker: {
      background: '#FFF',
      color: '#111',
      fontWeight: 'bold',
      fontSize: '12px',
      letterSpacing: '-0.2px',
      lineHeight: '20px',
      borderRadius: '15px',
      width: '60px',
      height: '30px',
      textAlign: 'center',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    marker: {
      width: '6px',
      minWidth: '6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        width: '6px',
      },
    },
    chapterDot: {
      width: '4px',
      height: '4px',
      backgroundColor: 'white',
      borderRadius: '50%',
      display: 'inline-block',
    },
    vimeoIframe: {
      opacity: '1',
      transition: 'opacity 0.3s 0.6s ease-in',
      '&.hideVideo': {
        opacity: 0,
        transition: 'opacity 0.2s 0.5s ease-in',
      }
    },
    volumeContainer: {
      position: 'absolute',
      top: '40px',
      left: '40px',
      zIndex: 3,
      transition: 'opacity 0.3s ease-in',
      '&.hideVolume': {
        opacity: 0,
        transition: 'opacity 0.3s ease-in',
      },
      [theme.breakpoints.down('xs')]: {
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '108px',
        width: '108px',
        '& .vibe-volume-bar': {
          marginRight: 0,
        }
      },
    },
    connectWalletBtn: {
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '30px',
      border: 0,
      cursor: 'pointer',
      fontFamily: 'NB Akademie Std,Source Sans Pro,Arial,sans-serif',
      fontSize: '12px',
      fontWeight: 700,
      letterSpacing: '-0.2px',
      textTransform: 'uppercase',
      padding: '13px 20px',
      position: 'absolute',
      right: 40,
      top: 40,
      transition: 'opacity 0.2s ease-in',
      zIndex: 9,
      opacity: 1,
      '&.hideConnectWallet': {
        opacity: '0',
        transition: 'opacity 0.2s ease-in',
      },
      '&.mobile': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50px',
        width: '50px',
        top: '30px',
        right: 30,
        zIndex: 9,
        '&.mobileLandscape': {
          top: '40px !important',
        },
        '&.connectedWallet': {
          backgroundColor: 'black',
          '& .MuiSvgIcon-root': {
            fill: 'white'
          }
        }
      },
      
    }
  })
);

export default function OnDemandPlayer() {
  const classes = useStyles();
  let vimeoId = 'https://player.vimeo.com/video/'+(process.env.REACT_APP_VIMEO_ID || '');
  let videoSrc = vimeoId + '?&controls=0&autoplay=1&muted=0&loop=0&playsinline=1';
  let thumbnailSrc = vimeoId + '?&controls=0&autoplay=0&muted=1&loop=0&playsinline=1';

  const isUserActive = useIsUserActive();

  const [videoMuted, setVideoMuted] = useState(!!(isMobile || isIOS));
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoAutoplayed, setVideoAutoplayed] = useState(!(isMobile || isIOS));
  const [videoPaused, setVideoPaused] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false);
  const [showIcons, setShowIcons] = useState(false);

  const [duration, setDuration] = useState<number>(0) // seconds - total length of video
  const [currentTime, setCurrentTime] = useState<number>(0) // seconds - current time location of video
  const [slidersByChapterTime, setSlidersByChapterTime] = useState<any>([]);

  const [isSeeking, setIsSeeking] = useState<boolean>(false); // if user is hovering on playbar
  const [seekingTime, setSeekingTime] = useState<number>(0); // seconds - time location of where user is hovering on playbar, determines time of thumbnail preview
  const [isSeekingChapter, setIsSeekingChapter] = useState<boolean | number>(false); // if user is hovering on marker
  const [seekingInChapter, setSeekingInChapter] = useState<number>(0); // chapter index - chapter location of where user is hovering on playbar

  const [volume, setVolume] = useState<number>(100); // (0 - 100) --> (0 - 1) --> (muted - max)

  // connect address stuff
  const prevSelectedWallet = sessionStorage.getItem('selectedWallet');
  const prevWalletAddress = sessionStorage.getItem('walletAddress');
  const [wallet, setWallet] = useState<any>(null);
  const [address, setAddress] = useState(prevWalletAddress ? prevWalletAddress : null);
  const [provider, setProvider] = useState(prevSelectedWallet ? prevSelectedWallet : null);
  const [showButton, setShowButton] = useState(prevWalletAddress ? false : true);
  const onboardRef = useRef<any>(null);

  const vimeoPlayer = useRef(null) as any;
  const thumbnailPlayer = useRef(null) as any;

  let vimeoIframe = document.getElementById('vimeoIframe');
  let thumbnailIframe = document.getElementById('thumbnailIframe');

  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const areaRef = useRef<HTMLDivElement[]>([]);
  const thumbnailIframeRef = useRef<HTMLIFrameElement>(null);
  const popperRef = useRef<Popper>(null);

  const title = process.env.REACT_APP_VIBE_ON_DEMAND_TITLE || '';
  const subtitle = process.env.REACT_APP_VIBE_ON_DEMAND_SUBTITLE || '';

  function formatDuration(value: number) {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute < 10 ? `0${minute}` : minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

 const togglePlayPause = () => {
    if (vimeoPlayer.current) {
      if (videoPaused) {
        vimeoPlayer.current.play();
        setVideoPaused(false);
        if (videoEnded) setVideoEnded(false);
      } else {
        vimeoPlayer.current.pause();
        setVideoPaused(true);
      }
    }
  };

  useEffect(() => {
    if (!videoLoaded) return;
    if (videoAutoplayed && !videoPaused) { 
      setVideoAutoplayed(false); 
      return;
    }
    setShowIcons(true);
    if (!videoPaused) {
      setTimeout(() => {
        setShowIcons(false);
      }, 1500);
    }
  }, [videoPaused]);

  const handleVideoClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();

    if (videoMuted) {
      setVideoMuted(false);
      if (!videoPaused) return;
    }

    togglePlayPause();
  }, [togglePlayPause]); 

  useEffect(() => {
    if (vimeoPlayer.current) {
      vimeoPlayer.current.setVolume(volume / 100)
        .then(() => {
          // do nothing
        })
        .catch(() => {
          // do nothing
        });
    }
  }, [volume])

  const handleMobileTouch = (e: React.TouchEvent) => {
    if (isMobile || isIOS) e.preventDefault();
  };

  useEffect(() => {
    if (vimeoIframe) {
      vimeoPlayer.current = new Player(vimeoIframe);

      const mainParticipantContainer = document.getElementById('mainParticipantContainer');
      const setHeight = () => {
        Promise.all([vimeoPlayer.current.getVideoWidth(), vimeoPlayer.current.getVideoHeight()]).then(function (
          dimensions
        ) {
          const videoWidth = dimensions[0];
          const videoHeight = dimensions[1];
          const containerWidth = mainParticipantContainer!.offsetWidth;
          const containerHeight = mainParticipantContainer!.offsetHeight;

          const videoRatio = videoWidth / videoHeight;
          const containerRatio = containerWidth / containerHeight;

          if (containerRatio === videoRatio) return;

          if (containerRatio > videoRatio) {
            const calculatedH = ((containerWidth * videoHeight) / videoWidth).toFixed(2);
            if (`${calculatedH}px` !== vimeoIframe!.style.width) {
              vimeoIframe!.style.height = `${calculatedH}px`;
              vimeoIframe!.style.width = '100%';
            }
          } else {
            const calculatedW = ((containerHeight * videoWidth) / videoHeight).toFixed(2);
            if (`${calculatedW}px` !== vimeoIframe!.style.width) {
              vimeoIframe!.style.width = `${calculatedW}px`;
              vimeoIframe!.style.height = '100%';
            }
          }
        });
      };

      // TODO: move chapter setup into CTRL
      const handleLoaded = (data: any) => {
        setHeight();
        vimeoPlayer.current.getDuration().then((time: number) => {
          setDuration(time);
          vimeoPlayer.current.getChapters().then((chapters: any) => {
            let sliderChapter = [];

            if (!_.isEmpty(chapters)) {
              // Setup first chapter
              if (chapters[0].startTime !== 0) {
                sliderChapter.push({ 
                  startTime: 0, 
                  endTime: chapters[0].startTime, 
                  title: '',
                  image: '',
                  percent:  (chapters[0].startTime) / time
                });
              }
              _.forEach(chapters, (chapter: any) => {
                const endTime = chapter.index === chapters.length ? time : chapters[chapter.index].startTime;
                sliderChapter.push({
                  startTime: chapter.startTime,
                  endTime,
                  title: chapter.title,
                  image: process.env.REACT_APP_CHAPTER_THUMBNAIL_URL+chapter.title,
                  percent: (endTime - chapter.startTime) / time
                });
              });
            } else {
              // No chapters -> 1 slider
              sliderChapter.push({
                startTime: 0,
                endTime: time,
                percent: 1,                
              });
            }

            setSlidersByChapterTime(sliderChapter);
          });
 
          vimeoPlayer.current.getPaused().then((paused : boolean) => {
            setVideoPaused(paused);
            if (paused) { 
              // setShowIcons(paused); 
            };
          });
        });

        setVideoLoaded(true);
      };

      vimeoPlayer.current.on('loaded', handleLoaded);

      const handleTimeUpdate = (data: any) => {
        if (data.seconds !== duration) { setVideoEnded(false) }
        setHeight();
        setCurrentTime(data.seconds);
      };

      vimeoPlayer.current.on('timeupdate', handleTimeUpdate);

      const handlePlay = (data: any) => {
        if (videoEnded) { setVideoEnded(false); }
        setVideoPaused(false);
      };

      vimeoPlayer.current.on('play', handlePlay);

      const handlePause = (data : any) => {
        setVideoPaused(true);
      }

      vimeoPlayer.current.on('pause', handlePause);

      const handleEnded = (data: any) => {
        setVideoEnded(true);
        setShowIcons(true);
      }

      vimeoPlayer.current.on('ended', handleEnded);

      const handleResize = () => {
        setHeight();
       };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    // eslint-disable-next-line
  }, [vimeoIframe]);

  useEffect(() => {
    if (thumbnailIframe && areaRef?.current) {
      thumbnailPlayer.current = new Player(thumbnailIframe);

      const setThumbnailHeight = () => {
        thumbnailIframe!.style.width = `240px`;
        thumbnailIframe!.style.height = '135px';
      };

      const handleThumbnailPlayerLoaded = () => {
        setThumbnailHeight();
      }

      thumbnailPlayer.current.on('loaded', handleThumbnailPlayerLoaded);

      const handleThumbnailTimeUpdate = (data: any) => {
        setThumbnailHeight();
        thumbnailPlayer.current.pause();
        setSeekingTime(data.seconds);
      }

      thumbnailPlayer.current.on('timeupdate', handleThumbnailTimeUpdate);
    }
  }, [thumbnailIframe, areaRef?.current]);

  // connect wallet useeffect
  useEffect(() => {
    onboardRef.current = Onboard({
      //dappId: process.env.REACT_APP_BLOCKNATIVE_API_KEY, // [String] The API key created by step one above
      networkId: 1,  // [Integer] The Ethereum network ID your Dapp uses.
      subscriptions: {
        address: address => {
          if (address) {
            window.sessionStorage.setItem('walletAddress', address);
            setAddress(address);
          }
        },
        wallet: (wallet: any) => {
           web3 = new Web3(wallet.provider);
           setWallet(wallet);
           if (wallet.name) {
             setProvider(wallet.name);
             window.sessionStorage.setItem('selectedWallet', wallet.name);
            }
           
        },
      },
      walletSelect: {
        wallets
      }
    });
  }, []);

  useEffect(() => {
    if (address) {
      axios({
        method: 'POST',
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        url: `/api/v1/sessions/${process.env.REACT_APP_SESSION_ID}/wallets/`,
        data: JSON.stringify({
          address
        }),
        headers: {
          'Content-type': 'application/json'
        }
      })
        .then(() => {
          // do nothing
        })
        .catch((err) => console.log("error posting address", err));
    }
  }, [address])

  const connectWallet = async () => {
    const selectedWallet = await onboardRef.current.walletSelect(provider ? provider : undefined);
    if (selectedWallet) await onboardRef.current.walletCheck();
  }

  const handleTimeSliderChange = (time: number) => {
    if (videoAutoplayed) { // on refresh, video needs to be interacted with to update time
      vimeoPlayer.current.play();
      if (videoPaused) {
        vimeoPlayer.current.pause();
      }
    }
    setCurrentTime(time);
    vimeoPlayer.current.setCurrentTime(time);
  }

  const handleSeeking = (event: React.MouseEvent) => {
    // |---------x--------------------|
    // left     current              right
    // Shift to 0 by subtracting playbar left position
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (isSeekingChapter) {
      const chapterStartTime = slidersByChapterTime[Number(isSeekingChapter)].startTime;
      setSeekingTime(chapterStartTime);
      if (thumbnailPlayer.current) { thumbnailPlayer.current.setCurrentTime(chapterStartTime); }
      return;
    }
    
    if (!areaRef.current) return;
    const sliderChapterArea = areaRef.current[seekingInChapter].getBoundingClientRect()
    const playbarLeft = sliderChapterArea.left || 0;
    const playbarRight = sliderChapterArea.right || 0;
    const playbarWidth = sliderChapterArea.width || 0;

    const currentX = event.clientX;
    if (currentX < playbarLeft || currentX > playbarRight) {
      return;
    }

    const calcX = currentX - playbarLeft;
    const percentSeekingInChapter = calcX / playbarWidth;
    const sliderChapter = slidersByChapterTime[seekingInChapter];
    const chapterDuration = sliderChapter.endTime - sliderChapter.startTime;
    let timeSeeking = (chapterDuration * percentSeekingInChapter) + sliderChapter.startTime;

    if (timeSeeking > duration) { timeSeeking = duration; }
    if (thumbnailPlayer.current) { thumbnailPlayer.current.setCurrentTime(timeSeeking); }
    setSeekingTime(timeSeeking);
  };

  return (
    <>
      <div className={clsx(classes.volumeContainer, { hideVolume:  (!isUserActive && !videoPaused) || !videoLoaded })}>
        <VolumeBar value={volume} updateVolume={(v) => setVolume(v) } isMuted={videoMuted} />
      </div>
      <button onClick={handleVideoClick} className={classes.button} onTouchStart={handleMobileTouch}>
        <span
          className={clsx(classes.circle, {
            show: (showIcons || (videoMuted && videoPaused)) && videoLoaded,
          })}
        >
          {videoEnded ? (
            <IconFlip className={classes.flipIcon}></IconFlip>
          ) : ( videoPaused ? (
            <IconPlay className={classes.playIcon}></IconPlay>
          ) : (
            <IconPause className={classes.pauseIcon}></IconPause>
          ))}
        </span>
      </button>
      <div className={clsx(classes.titleContainer, { hideTitle: !videoLoaded }, { shiftTitle: isUserActive || videoPaused })}>
        <p className={classes.title}>{title}</p>
        <p className={classes.subtitle}>{subtitle}</p>
      </div>
      <div className={clsx(classes.playerNavigationContainer, { hidePlaybar: (!isUserActive && !videoPaused) || !videoLoaded })}>
        <div className={classes.playerNavigationInner}>
          <span className={clsx(classes.time, classes.startTime)}>0:00</span>
          <Tooltip
            classes={{
              tooltip: classes.seekingTooltip,
              tooltipPlacementTop: classes.tooltipPlacementTop,
            }}
            open={isSeeking && isUserActive}
            title={
              <div className={classes.seekingContainer}>
                <div className={classes.seekingThumbnail}>
                  {isSeekingChapter && slidersByChapterTime[Number(isSeekingChapter)].title && 
                    <img src={slidersByChapterTime[Number(isSeekingChapter)].image} alt={slidersByChapterTime[Number(isSeekingChapter)].title} />
                  }
                  <iframe
                    ref={thumbnailIframeRef}
                    id="thumbnailIframe"
                    title="thumbnailIframe"
                    src={thumbnailSrc}
                    style={{
                      position: 'absolute',
                      zIndex: 4,
                      width: (isMobile || isIOS) ? '100px' : '240px',
                      height: (isMobile || isIOS) ? '56px' : '135px',
                      border: 'none',
                      margin: '0',
                      padding: '0',
                      overflow: 'hidden',
                      opacity: isSeekingChapter ? 0 : '1',
                      borderRadius: '15px',
                    }}
                    frameBorder="0"
                    allow="autoplay"
                  />
                </div>
                <div className={classes.valueLabelSeeker}>
                  {formatDuration(seekingTime)}
                </div>
              </div>
            }
            placement="top"
            PopperProps={{
              className: clsx('MuiTooltip-popper', classes.popper),
              keepMounted: true,
              popperRef,
              anchorEl: {
                clientWidth: 0,
                clientHeight: 0,
                getBoundingClientRect: () => {
                  return new DOMRect(
                    positionRef.current.x,
                    areaRef.current[seekingInChapter].getBoundingClientRect().y,
                    0,
                    0,
                  );
                },
              },
            }}
          >
            <Box
              className={classes.timeSlider}
              onMouseMove={handleSeeking}
              onMouseEnter={() => setIsSeeking(true)}
              onMouseLeave={() => setIsSeeking(false)}
              onMouseDown={() => setIsSeeking(false)}
              // {...{ ref: areaRef } as any}
            >
              {
                _.map(slidersByChapterTime, (chapter: any, key: number) => {
                  return (
                    <div key={key} className={classes.chapterContainer} style={{ width: String(chapter.percent * 100)+'%'}}>
                      {chapter.title &&
                        <Box
                          className={classes.marker}
                          sx={{
                            width: '6px',
                            height: '6px',
                          }}
                          onMouseMove={() => setIsSeekingChapter(key)}
                          onMouseLeave={() => setIsSeekingChapter(false)}
                          onClick={() => handleTimeSliderChange(chapter.startTime)} >
                            <span className={classes.chapterDot} />
                        </Box>
                      }
                      <Box 
                        onMouseMove={() => setSeekingInChapter(key)} 
                        sx={{ width: '100%', height: '100%', justifyContent: 'center' }}>
                        <Slider
                          innerRef={(ref) => areaRef.current[key] = ref }
                          classes={{
                            root: classes.root,
                            rail: classes.rail,
                            track: classes.track,
                            thumb: classes.thumb,
                            valueLabel: classes.valueLabel,
                          }}
                          value={Math.max(currentTime, chapter.startTime)}
                          min={chapter.startTime}
                          max={chapter.endTime}
                          valueLabelDisplay={currentTime < chapter.endTime && currentTime > chapter.startTime ? 'auto' : 'off'}
                          valueLabelFormat={formatDuration}
                          onChange={(_, value) => handleTimeSliderChange(value as number)}
                        />
                      </Box>
                    </div>
                  )
                })
              }
            </Box>
          </Tooltip>
          <span className={clsx(classes.time, classes.endTime)}>{formatDuration(duration)}</span>
        </div>
      </div>
      <iframe
        id="vimeoIframe"
        title="vimeoIframe"
        src={videoSrc}
        className={clsx(classes.vimeoIframe, { hideVideo: !videoLoaded })}
        style={{
          zIndex: 1,
          position: 'absolute',
          top: '0',
          left: '50%',
          bottom: '0',
          right: '0',
          transform: 'translateX(-50%)',
          width: '100%',
          height: '100%',
          // minHeight: '100vh',
          border: 'none',
          margin: '0',
          padding: '0',
          overflow: 'hidden',
        }}
        frameBorder="0"
        allow="autoplay"
        scrolling="no"
        allowFullScreen
        data-vimeo-iframe
      ></iframe>
      {/* {videoMuted && <TapForSound />} */}
      {!!process.env.REACT_APP_WALLET_CONNECT && process.env.REACT_APP_WALLET_CONNECT === 'true' && window.location.pathname === '/SS22' && (
        <button 
          className={clsx(classes.connectWalletBtn, { 
            hideConnectWallet: (window.screen.width < 521 && !isUserActive && !videoPaused) || !videoLoaded, 
            mobile: window.screen.width < 521,
            mobileLandscape: window.screen.width < 521 && window.innerHeight < window.innerWidth,
            connectedWallet: window.screen.width < 521 && !!address
          })}
          onClick={connectWallet}
        >
          {window.screen.width > 521 ? `${address ? 'wallet connected' : 'connect wallet'}` : <AccountBalanceWalletIcon />}
        </button>
      )}
    </>
  );
}
