import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
//import { isMobile, isIOS, isSafari, isPortrait } from '../../utils';

import { usePlayerState } from './PlayerProvider';
import TapForSound from './TapForSound';
import { isIOS, isMobile, isSafari } from './utils';
import Player from '@vimeo/player';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%',
      width: '100%',
      zIndex: 20,
    },
    banner: {
      margin: '0',
      backgroundColor: '#FFFFFF',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '21.5px',
      padding: '20px 0',
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
      },
    },
    text: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      animation: '$sliding 10s linear infinite',
    },
    assetContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    video: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 1,
    },
    iframeContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    vimeoIframeDesktop: {
      width: '100vw',
      height: '56.25vw',
      minHeight: '100vh',
      minWidth: '177.77vh',
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: -1,
      transform: 'translate(-50%, -50%)',
    },
    vimeoIframeMobile: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    image: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 0,
      // objectPosition: 'top',
    },
    desktopVideo: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    mobileVideo: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    '@keyframes sliding': {
      '0%': {
        transform: 'translateX(0)',
      },
      '100%': {
        transform: 'translateX(-10%)',
      },
    },
  })
);

export default function Preroll() {
  const { URLVibeChannel } = useParams();
  const { vibeConfig } = usePlayerState();

  const videoRef = useRef();
  const iframeDesktop = useRef(null);
  const iframeMobile = useRef(null);
  const vimeoPlayerDesktop = useRef(null);
  const vimeoPlayerMobile = useRef(null);

  let locationPrerolls = [];
  let locationPrerollsMob = [];

  let locationPrerollsImg = [];
  let locationPrerollsImgMob = [];

  const preRollBannerText = vibeConfig ? vibeConfig?.preroll?.banner_text : process.env.REACT_APP_PREROLL_BANNER_TEXT;

  if (process.env.REACT_APP_LOCATION_PREROLL_VIDEO) {
    let locationPrerollString = process.env.REACT_APP_LOCATION_PREROLL_VIDEO.split(',');

    locationPrerollString.forEach(l => {
      const parts = l.split('|');
      locationPrerolls = [...locationPrerolls, { location: parts[0], url: parts[1] }];
    });
  }

  if (process.env.REACT_APP_LOCATION_PREROLL_VIDEO_MOBILE) {
    let locationPrerollMobString = process.env.REACT_APP_LOCATION_PREROLL_VIDEO_MOBILE.split(',');

    locationPrerollMobString.forEach(l => {
      const parts = l.split('|');
      locationPrerollsMob = [...locationPrerollsMob, { location: parts[0], url: parts[1] }];
    });
  }

  if (process.env.REACT_APP_LOCATION_PREROLL_IMAGE) {
    let locationPrerollImgString = process.env.REACT_APP_LOCATION_PREROLL_IMAGE.split(',');

    locationPrerollImgString.forEach(l => {
      const parts = l.split('|');
      locationPrerollsImg = [...locationPrerollsImg, { location: parts[0], url: parts[1] }];
    });
  }

  if (process.env.REACT_APP_LOCATION_PREROLL_IMAGE_MOBILE) {
    let locationPrerollImgStringMob = process.env.REACT_APP_LOCATION_PREROLL_IMAGE_MOBILE.split(',');

    locationPrerollImgStringMob.forEach(l => {
      const parts = l.split('|');
      locationPrerollsImgMob = [...locationPrerollsImgMob, { location: parts[0], url: parts[1] }];
    });
  }

  const locationPrerollVideo = URLVibeChannel
    ? locationPrerolls.find(l => l.location === URLVibeChannel.toLowerCase())
    : undefined;
  const locationPrerollMobVideo = URLVibeChannel
    ? locationPrerollsMob.find(l => l.location === URLVibeChannel.toLowerCase())
    : undefined;

  const locationPrerollImg = URLVibeChannel
    ? locationPrerollsImg.find(l => l.location === URLVibeChannel.toLowerCase())
    : undefined;
  const locationPrerollMobImg = URLVibeChannel
    ? locationPrerollsImgMob.find(l => l.location === URLVibeChannel.toLowerCase())
    : undefined;

  const preRollVideo = vibeConfig
    ? vibeConfig?.preroll?.desktop?.video_url
    : locationPrerollVideo
    ? locationPrerollVideo?.url
    : !locationPrerollImg
    ? process.env.REACT_APP_PREROLL
    : undefined;

  const preRollVideoMobile = vibeConfig
    ? vibeConfig?.preroll?.mobile?.video_url
    : locationPrerollMobVideo
    ? locationPrerollMobVideo?.url
    : !locationPrerollMobImg
    ? process.env.REACT_APP_PREROLL_MOBILE
    : undefined;

  // console.log(preRollVideo, vibeConfig?.preroll?.desktop?.video_url);

  const preRollImage = vibeConfig ? vibeConfig?.preroll?.desktop?.image_url : process.env.REACT_APP_PREROLL_IMAGE;
  const preRollImageMobile = vibeConfig
    ? vibeConfig?.preroll?.mobile?.image_url
    : process.env.REACT_APP_PREROLL_IMAGE_MOBILE;

  // console.log('rendering');

  const classes = useStyles();
  const [isMuted, setIsMuted] = useState(Boolean(isMobile || isIOS || isSafari()));

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  // eslint-disable-next-line
  }, [videoRef.current, isMuted])

  const vimeoIframeLobbyDesktop = document.getElementById('vimeoIframeLobbyBgDesktop');
  const vimeoIframeLobbyMobile = document.getElementById('vimeoIframeLobbyBgMobile');

  useEffect(() => {
    if (vimeoIframeLobbyDesktop) {
      vimeoPlayerDesktop.current = new Player(vimeoIframeLobbyDesktop);

      const volume = isMuted ? 0 : 1;
      vimeoPlayerDesktop.current.setVolume(volume)
        .then(() => {
          // do nothing
        })
        .catch(() => {
          // do nothing
        });
    }
    if (vimeoIframeLobbyMobile) {
      vimeoPlayerMobile.current = new Player(vimeoIframeLobbyMobile);

      const volume = isMuted ? 0 : 1;
      vimeoPlayerMobile.current.setVolume(volume)
        .then(() => {
          // do nothing
        })
        .catch(() => {
          // do nothing
        });
    }
  }, [vimeoIframeLobbyDesktop, vimeoIframeLobbyMobile]);

  useEffect(() => {
    const unmute = () => {
      setIsMuted(false);
      document.querySelectorAll('.pre-roll-video').forEach(v => {
        v.pause();
        v.muted = false;
        setTimeout(() => {
          v.play();
        }, 200);
      });
      if (vimeoPlayerDesktop.current) {
        vimeoPlayerDesktop.current.setVolume(1)
        .then(() => {
          // do nothing
        })
        .catch(() => {
          // do nothing
        });
      }
      if (vimeoPlayerMobile.current) {
        vimeoPlayerMobile.current.setVolume(1)
          .then(() => {
            // do nothing
          })
          .catch(() => {
            // do nothing
          });
      }
    };

    if (isMuted) {
      document.addEventListener('click', unmute, { once: true });
    }

    return () => {
      document.removeEventListener('click', unmute);
    };
  });

  const isVimeoVideo = (url) => !!url.match(/https:\/\/player.vimeo.com\/video/gi);

  // const bannerText = preRollBannerText || 'PLEASE WAIT FOR THE HOST TO BEGIN PRADA VIRTUAL STYLING SESSION - ';
  const bannerText = preRollBannerText || '';

  return (
    <div className={clsx(classes.container, 'preroll-container')}>
      {bannerText.length > 0 && (
        <p className={clsx(classes.banner, 'preroll-ticker')}>
          <span className={classes.text}>{`${bannerText} `.repeat(10)}</span>
        </p>
      )}
      <div className={classes.assetContainer}>
        {(preRollVideo || preRollVideoMobile) && !isVimeoVideo(preRollVideo) && !isVimeoVideo(preRollVideoMobile) && (
          <video
            ref={videoRef}
            className={clsx('pre-roll-video', classes.video)}
            loop
            playsInline
            autoPlay
            src={preRollVideo || preRollVideoMobile}
          ></video>
        )}
        {preRollVideo && isVimeoVideo(preRollVideo) && window.screen.width > 520 && (
          <div className={classes.iframeContainer}>
            <iframe
              id="vimeoIframeLobbyBgDesktop"
              title="vimeoIframeLobbyBgDesktop"
              ref={iframeDesktop}
              className={`pre-roll-iframe ${classes.vimeoIframeDesktop} ${preRollVideo ? classes.desktopVideo : ''}`}
              src={`${preRollVideo}?&controls=0&autoplay=1&muted=0&loop=1&playsinline=1`}
              frameBorder="0"
              allow="autoplay"
              allowFullScreen
            />
          </div>
        )}
        {preRollVideoMobile && isVimeoVideo(preRollVideoMobile) && window.screen.width < 521 &&(
          <iframe
            id="vimeoIframeLobbyBgMobile"
            title="vimeoIframeLobbyBgMobile"
            ref={iframeMobile}
            className={`pre-roll-iframe ${classes.vimeoIframeMobile}`}
            src={`${preRollVideoMobile}?&controls=0&autoplay=1&muted=0&loop=1&playsinline=1`}
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
          />
        )}
        {isMuted && (preRollVideo || preRollVideoMobile) && <TapForSound />}
        {locationPrerollImg && (
          <>
            <img
              className={clsx(classes.image, 'preroll-image')}
              src={isMobile && locationPrerollMobImg ? locationPrerollMobImg.url : locationPrerollImg.url}
              alt=""
            />
          </>
        )}
        {preRollImage && !locationPrerollImg && (
          <>
            <img
              className={clsx(classes.image, 'preroll-image')}
              src={isMobile && preRollImageMobile ? preRollImageMobile : preRollImage}
              alt=""
            />
          </>
        )}
      </div>
    </div>
  );
}
