import React, { useCallback } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import MessageList from './MessageList/MessageList';
import useChatContext from '../hooks/useChatContext/useChatContext';
import IconCloseChat from './IconCloseChat';
import StreamChatInput from './ChatInput/StreamChatInput';
import { Rnd } from 'react-rnd';
import useHeight from '../hooks/useHeight/useHeight';
import useWidth from '../hooks/useWidth/useWidth';
import { isMobile } from '../utils';
import IconClose from '../Icons/IconClose';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWindowContainer: {
      display: 'block',
      opacity: 1,
      margin: 'auto',
      position: 'relative',
      // background: '#FFFFFF',
      background: 'linear-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,1) 56%, rgba(255,255,255,0) 100%)',
      zIndex: 21,
      // top: 0,
      // right: 0,
      // bottom: 0,
      height: '710px',
      width: '420px',
      // margin: '20px',
      boxShadow:
        '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
      borderRadius: '30px',
      border: 'none',
      [theme.breakpoints.down('sm')]: {
        // margin: '8px auto',
      },
      [theme.breakpoints.down('xs')]: {
        // height: '98%',
        // display: 'flex',
        // flexDirection: 'column',
      },
      transition: 'opacity 0.35s ease',
      '&:hover #closeChatWindowButtonContainer': {
        visibility: 'visible',
        opacity: 1,
        transition: 'opacity 0.2s ease-in',
      },
    },
    chatContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    hide: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0.5s 0.5s, opacity 0.35s ease',
      zIndex: 5,
    },
    closeButtonContainer: {
      // visibility: 'hidden',
      // opacity: 0,
      // position: 'absolute',
      // top: 0,
      // right: 0,
      // padding: '20px',
      // zIndex: 22,
      transition: 'visibility 0.2s 0.2s, opacity 0.2s ease-in',
    },
    mobileCloseButton: {
      visibility: 'visible',
      opacity: 1,
    },
    closeButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      '& svg': {
        width: '30px',
        height: '30px',
      },
    },
    chatHeader: {
      padding: '20px 20px 20px 30px',
      background: '#FFFFFF',
      borderTopLeftRadius: '30px',
      borderTopRightRadius: '30px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: '20px',
      lineHeight: '40px',
      fontWeight: 700,
      letterSpacing: '-0.2px',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

export default function ChatWindow() {
  const classes = useStyles();
  const { isChatWindowOpen, messages, conversation, vibeChat, setIsChatWindowOpen, setNewMessages } = useChatContext();
  const { height } = useHeight();

  const canSendPublicMessage = vibeChat?.options.public || vibeChat?.options.host;

  const handleCloseChatWindow = useCallback(() => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setNewMessages(0);
  }, [setIsChatWindowOpen, isChatWindowOpen]);

  const pxHeight = Number(height.split('px')[0]);
  const windowHeight = isMobile ? pxHeight - 16 : pxHeight * (2 / 3);
  const defaultY = pxHeight / 2 - windowHeight / 2;

  const pxWidth = Number(useWidth().split('px')[0]);
  const windowWidth = isMobile ? pxWidth - 16 : 420;
  const defaultX = pxWidth / 2 - windowWidth / 2;

  return (
    <Rnd
      className={clsx(classes.chatWindowContainer, { [classes.hide]: !isChatWindowOpen })}
      default={{
        x: defaultX,
        y: defaultY,
        width: windowWidth,
        height: windowHeight,
      }}
      minWidth={isMobile ? windowWidth : 420}
      maxWidth={isMobile ? windowWidth : 420}
      minHeight={250}
      maxHeight="100%"
      enableResizing={{
        bottom: !isMobile,
        bottomLeft: !isMobile,
        bottomRight: !isMobile,
        left: false,
        right: false,
        top: !isMobile,
        topLeft: !isMobile,
        topRight: !isMobile,
      }}
      allowAnyClick={false}
      bounds="window"
      disableDragging={isMobile}
      cancel="textarea, a, button"
    >
      <div className={classes.chatContainer}>
        <div className={classes.chatHeader}>
          <span className={classes.title}>
            CHAT
          </span>
          <div
            className={clsx(classes.closeButtonContainer, { [classes.mobileCloseButton]: isMobile })}
          >
            <button className={classes.closeButton} type="button" onClick={handleCloseChatWindow}>
              <IconClose className={classes.closeButton} />
            </button>
          </div>
        </div>
        <MessageList messages={messages} />
        <StreamChatInput
          conversation={conversation!}
          isChatWindowOpen={isChatWindowOpen}
          placeholder={canSendPublicMessage ? 'Send to everyone' : 'Send a private message to the host'}
          isHost={vibeChat?.options.host}
          isPublicChat={vibeChat?.options.public}
        />
      </div>
    </Rnd>
  );
}
