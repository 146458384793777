import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import CallEnd from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

//import { isIOS } from '../../../utils';


import { usePlayerState } from './PlayerProvider';
import IconClose from './Icons/IconClose';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#111',
      color: '#FFF',
      padding: '15px 20px 13px',
      borderRadius: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    arrow: {
      color: '#111',
    },
    fab: {
      backgroundColor: '#fff',
      color: '#111',
      margin: '5px',
      boxShadow: 'none',
      [theme.breakpoints.down('md')]: {
        height: '50px',
        width: '50px',
        margin: '5px 7.5px',
        backgroundColor: '#fff',
        color: '#111',
      },
      '&.device:hover, &.device:focus': {
        backgroundColor: '#fff',
        color: '#111',
      },
      '&:hover': {
        [theme.breakpoints.down('md')]: {
          backgroundColor: '#fff',
          color: '#111',
        },
      },
    },
    closeIcon: {
      width: '30px',
      height: '30px',
    },
  })
);

export default function StreamEndButton() {
  const classes = useStyles();
  const { disconnect } = usePlayerState();
  // const { room, isSharingScreen, toggleScreenShare, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();

  const handleClick = () => disconnect();

  return (
    <Tooltip
      title={'EXIT'}
      onClick={handleClick}
      placement="top"
      PopperProps={{ disablePortal: true }}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow, touch: classes.tooltipTouch }}
      arrow
      data-cy-disconnect
    >
      <div>
        {/* <Fab className={clsx(classes.fab, { device: isIOS })}> */}
        <Fab className={clsx(classes.fab)}>
          <IconClose className={classes.closeIcon} />
        </Fab>
      </div>
    </Tooltip>
  );
}
