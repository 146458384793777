import React, { useCallback, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconMessageBubble from './Icons/IconMessageBubble';

import useChatContext from './hooks/useChatContext/useChatContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: '5px',
      position: 'relative',
      boxShadow: 'none',
      [theme.breakpoints.down('md')]: {
        height: '50px',
        width: '50px',
        margin: '5px 7.5px',
        backgroundColor: '#FFF',
      },
      '&[disabled]': {
        color: 'rgba(225, 225, 225, 0.8)',
        backgroundColor: 'rgba(175, 175, 175, 0.6);',
      },
    },
    blackFab: {
      background: '#111',
      color: '#FFF',
      '&:hover': {
        background: '#111',
        color: '#FFF',
      },
    },
    fabLabel: {
      pointerEvents: 'none',
    },
    tooltip: {
      lineHeight: '11.42px',
      fontSize: '10px',
      background: '#111',
      color: '#fff',
      padding: '15px 20px 13px',
      borderRadius: '24px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    tooltipTouch: {
      fontSize: '10px',
      lineHeight: '11.42px',
    },
    blackArrow: {
      color: '#111',
    },
    circle: {
      position: 'absolute',
      width: '14px',
      height: '14px',
      right: '9px',
      top: '10px',
      background: '#00FFFF',
      borderRadius: '100%',
      border: '2px solid #FFF',
    },
  })
);

export default function StreamChatButton({ disabled }) {
  const classes = useStyles();

  const { newMessages, setNewMessages, setIsChatWindowOpen, isChatWindowOpen } = useChatContext();

  const handleToggleChat = useCallback(() => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setNewMessages(0);
  }, [setIsChatWindowOpen, isChatWindowOpen]);

  return (
    <Tooltip
      title={newMessages ? `${newMessages} NEW` : 'CHAT'}
      placement="top"
      PopperProps={{ disablePortal: true }}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.blackArrow,
        touch: classes.tooltipTouch,
      }}
      arrow
      // open
    >
      <div>
        <Fab
          className={clsx(classes.fab, { [classes.blackFab]: isChatWindowOpen })}
          classes={{ label: classes.fabLabel }}
          id="vibe-chat-trigger"
          onClick={handleToggleChat}
        >
          <IconMessageBubble />
          {newMessages ? <span className={classes.circle} /> : <></>}
        </Fab>
      </div>
    </Tooltip>
  );
}
