import { makeStyles, createStyles } from "@material-ui/core";
import IconSpinner from "./Icons/IconSpinner";
import { usePlayerState } from "./PlayerProvider";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
      background: 'transparent',
      zIndex: 10,
    },
    iconHolder: {
      display: 'flex',
      padding: '16px',
      width: '240px',
      height: '240px',
      justifyContent: 'center',
      alignItems: 'center',
      backdropFilter: 'blur(15px)',
      borderRadius: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '117px',
        height: '117px',
      },
    },
    iconSpinner: {
      color: 'white',
      width: '108px',
      height: '108px',
      [theme.breakpoints.down('xs')]: {
        width: '40px',
        height: '40px',
      },
    },
    letter: {
      animationName: '$loadingF',
      animationDuration: '2.6s',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate-reverse',
      transition: '200ms',
      float: 'left',
      fontFamily: theme.typography.fontFamily,
      fontSize: '16px',
      color: 'white',
    },
    sign: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '16px',
      fontWeight: 600,
      textAlign: 'center',
      color: 'white',
    },
  }))


export default function StreamReconnect () {

  const { playerState } = usePlayerState();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.iconHolder}>
        {playerState === 'reconnecting' ? (
        /* {true ? ( */
          <IconSpinner className={classes.iconSpinner} />
          /* ) : false ? ( */
        ) : playerState === 'disconnecting' ? (
          <div className={classes.sign} >WE WERE UNABLE TO RECONNECT</div>
        ) : null}
      </div>
    </div>
  );
}