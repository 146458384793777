import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Message } from '@twilio/conversations/lib/message';
import MessageListScrollContainer from './MessageListScrollContainer/MessageListScrollContainer';
import TextMessage from './TextMessage/TextMessage';
import clsx from 'clsx';
import IconRemoveMessage from './IconRemoveMessage';
import linkify from 'linkify-it';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import _ from 'lodash';
import TypingLoader from '../ChatInput/TypingLoader';
import { ParticipantAttributes } from '../../ChatProvider';

interface MessageListProps {
  messages: Message[];
}

const useStyles = makeStyles(theme =>
  createStyles({
    messageContainer: {
      display: 'flex',
      width: 'auto',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: '15px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      letterSpacing: '-0.333333px',
      padding: '0 20px 0 30px',
      borderRadius: 0,
      // padding: '0 1.2em',
      // borderBottomLeftRadius: 0,
      // borderBottomRightRadius: 0,
      '&:hover': {
        '& button': {
          visibility: 'visible',
          opacity: 1,
          transition: 'visibility 0.2s 0.2s, opacity 0.2s ease-in',
        },
      },
      '& button': {
        visibility: 'hidden',
        opacity: 0,
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        transition: 'visibility 0.2s 0.2s, opacity 0.2s ease-in',
      },
    },
    // '&:first-child button': {
    //   marginRight: '36px',
    // },
    // '&:nth-child(2) button': {
    //   marginRight: '36px',
    // },
    containsLinkPreview: {
      position: 'relative',
    },
    messageActions: {
      marginLeft: 'auto',
      alignSelf: 'center',
    },
    actionButton: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '11px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    removeMessageButton: {
      '&:hover circle': {
        fill: '#FF6666',
        transition: 'fill 0.2s ease-in',
      },
    },
    positionWithLink: {
      position: 'absolute',
      top: 0,
      right: '20px',
    },
  })
);

const getFormattedTime = (message?: Message) =>
  message?.dateCreated.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }).toLowerCase();

export default function MessageList({ messages }: MessageListProps) {
  const classes = useStyles();
  const { localParticipantMessages, typingParticipant, vibeChat, chatParticipants, conversation, setChatParticipants } = useChatContext();

  return (
    <MessageListScrollContainer messages={messages}>
      {messages?.map((message, idx) => {
        // const time = getFormattedTime(message)!;
        // const previousTime = getFormattedTime(messages[idx - 1]);

        // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        // const shouldDisplayMessageInfo = time !== previousTime || message.author !== messages[idx - 1]?.author;

        const isLocalParticipant = _.includes(localParticipantMessages, message.index);
        const getParticipantAttributes = () => {
          let participantAttributes: ParticipantAttributes | undefined;
          if (conversation && !chatParticipants.get(message.author)) {
            conversation.getParticipantByIdentity(message.author).then((participant: Participant) => {
              setChatParticipants(new Map(chatParticipants.set(participant.identity, participant)));
              participantAttributes = participant.attributes;
            });
          }
          participantAttributes = chatParticipants.get(message.author)?.attributes;
          return participantAttributes;
        };
        const authorName = getParticipantAttributes()?.name || message.author.split('-')[0] || ''; // keep fallback for old conversations

        const handleDeleteMessage = () => message.remove();

        const containsLink = linkify()
          .tlds('video', true)
          .add('vibe:', 'https:')
          .match(message.body);

        return (
          <div
            key={message.sid}
            className={clsx(classes.messageContainer, { [classes.containsLinkPreview]: containsLink })}
          >
            <>
                {message.type === 'text' && (
                  <TextMessage body={message.body} author={authorName} />
                )}
                <div className={classes.messageActions}>
                  {isLocalParticipant && (
                    <button
                      className={clsx(classes.actionButton, classes.removeMessageButton, {
                        [classes.positionWithLink]: containsLink,
                      })}
                      onClick={handleDeleteMessage}
                    >
                      <IconRemoveMessage />
                    </button>
                  )}
                </div>
              </>
            </div>
        );
      })}
      {vibeChat?.options.host && typingParticipant && (
        <div key={'user-typing'} className={clsx(classes.messageContainer)}>
          <TextMessage body="" author={typingParticipant} /> <TypingLoader />
        </div>
      )}
    </MessageListScrollContainer>
  );
}
