import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { isMobile } from '../../utils';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import IconSend from './IconSend';
import _ from 'lodash';
import linkify from 'linkify-it';

const useStyles = makeStyles(theme => ({
  chatInputContainer: {
    // backgroundColor: '#292929',
    padding: '25px 20px',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
    // minHeight: '70px',
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(20px)',
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textArea: {
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    width: '100%',
    border: '0',
    resize: 'none',
    fontSize: '15px',
    lineHeight: '22.5px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    letterSpacing: '-0.333333px',
    padding: 0,
    '&:focus': {
      outline: 'none',
      '&::placeholder': {
        color: '#666666',
        transition: 'color 0.25s ease',
      },
    },
    '&::placeholder': {
      color: 'white',
      transition: 'color 0.25s ease',
    },
  },
  button: {
    '& svg': {
      width: '30px',
      height: '30px',
    },
    '& path': {
      fill: '#FFFFFF',
    },
    '&:hover': {
      background: 'none',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '100%',
    backgroundColor: '#000000',
  },
  hideSendButton: {
    display: 'none',
  },
  textAreaContainer: {
    minHeight: '1px',
    display: 'flex',
    flexGrow: 1,
  },
  isTextareaFocused: {
    border: 'none',
    borderColor: '#111',
  },
}));

interface ChatInputProps {
  conversation: Conversation;
  isChatWindowOpen: boolean;
  placeholder?: string;
  isHost?: boolean;
  isPublicChat?: boolean;
}

export default function ChatInput({
  conversation,
  isChatWindowOpen,
  placeholder,
  isHost,
  isPublicChat,
}: ChatInputProps) {
  const classes = useStyles();
  const { getLinkPreview, linkCache, setLinkCache } = useChatContext();
  const [messageBody, setMessageBody] = useState('');
  const isValidMessage = /\S/.test(messageBody);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const { addLocalParticipantMessage } = useChatContext();

  const containsLinks = (text: string) => {
    return linkify()
      .tlds('video', true)
      .add('vibe:', 'https:')
      .match(text);
  };

  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      textInputRef.current?.focus();
    }
  }, [isChatWindowOpen]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    // Send notification to server that current user has began typing
    conversation?.typing();
    setMessageBody(value);  
    let links = containsLinks(value);
    if (!_.isEmpty(links)) {
      links?.forEach((match, i) => {
        const { url } = match;
        if (!linkCache.has(url)) {
          getLinkPreview(url).then((data: any) => {
            setLinkCache(new Map(linkCache.set(url, data)));
          });
        }
      });
    }
  };

  const handleSendMessage = (message: string) => {
    if (isValidMessage && conversation) {
      conversation.sendMessage(
        message?.trim(),
        JSON.stringify({ 
          host: isHost, 
          roomParticipantId: 'STREAM',
          public: isPublicChat,
        })
      ).then((messageIndex : number) => {
        addLocalParticipantMessage(messageIndex);
      });
      setMessageBody('');
    }
  };

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(messageBody);
    }
  };

  return (
    <div className={clsx(classes.chatInputContainer, { [classes.mobileContainer]: isMobile })}>
      <div className={clsx(classes.textAreaContainer, { [classes.isTextareaFocused]: isTextareaFocused })}>
        {/* 
        Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
        This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
        component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
        */}
        <TextareaAutosize
          minRows={1}
          maxRows={3}
          className={classes.textArea}
          aria-label="chat input"
          placeholder={placeholder}
          onKeyPress={handleReturnKeyPress}
          onChange={handleChange}
          value={messageBody}
          data-cy-chat-input
          ref={textInputRef}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => setIsTextareaFocused(false)}
        />
      </div>

      <div className={clsx(classes.buttonContainer, { [classes.hideSendButton]: !isMobile })}>
        <Button
          className={classes.button}
          onClick={() => handleSendMessage(messageBody)}
          color="primary"
          disabled={!isValidMessage}
          data-cy-send-message-button
        >
          <IconSend />
        </Button>
      </div>
    </div>
  );
}
