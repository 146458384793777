import React, { useState, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Popper, Fade, TextField, InputAdornment, Fab, Tooltip, Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { CopyToClipboard } from 'react-copy-to-clipboard';

//import { isIOS } from '../../../utils';

import IconClose from './Icons/IconClose';
import IconCopy from './Icons/IconCopy';
import IconCheckmark from './Icons/IconCheckmark';
import useIsUserActive from './hooks/useIsUserActive/useIsUserActive';
import { isMobile } from './utils';
import IconInvite from './Icons/IconInvite';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inviteButton: {
            background: '#FFFFFF',
            backdropFilter: 'blur(20px)',
            height: '60px',
            padding: '0px 30px',
            color: '#111',
            margin: '5px',
            boxShadow: 'none',
            borderRadius: '30px',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '60px',
            letterSpacing: '-0.2px',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('md')]: {
                // height: '50px',
                // width: '50px',
                // margin: '5px 7.5px',
                backgroundColor: '#fff',
                color: '#111',
            },
            '&.device:hover, &.device:focus': {
                color: '#fff',
                backgroundColor: '#111',
            },
            '&:hover': {
                // [theme.breakpoints.down('md')]: {
                //     backgroundColor: '#fff',
                //     color: '#111',
                // },
            },
        },
        showPopup: {
            color: '#fff',
            backgroundColor: '#111',
        },
        paper: {
            borderRadius: '30px',
            backgroundColor: '#FFFFFF',
            padding: '20px 30px',
        },
        dialogTitle: {
            width: 'auto',
            padding: 0,
            marginBottom: '10px',
        },
        header: {
            marginRight: '-10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        title: {
            fontSize: '20px',
            lineHeight: '40px',
            fontWeight: 700,
            letterSpacing: '-0.2px',
            display: 'flex',
            alignItems: 'center',
        },
        closeButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            '& svg': {
                width: '30px',
                height: '30px',
            },
        },
        content: {
            padding: 0,
            width: '100%'
        },
        // container: {
        //     // position: 'relative',
        //     // width: '342px',
        //     // display: 'flex',
        //     // zIndex: 5,
        //     // justifyContent: 'flex-end',
        //     // alignItems: 'flex-end',
        //     // height: '70px',
        //     // transition: 'height 0.35s ease, opacity 1.2s, visibility 0s 1.2s',
        //     opacity: 0,
        //     // '&.showing-invite-popup': {
        //     //     height: '150px',
        //     // },
        //     // '&.showInviteButton, &:hover, &.showing-invite-popup': {
        //     //     transition: 'opacity 0.6s, visibility 0s, height 0.35s ease',
        //     //     opacity: 1,
        //     //     visibility: 'visible',
        //     // },
        //     [theme.breakpoints.down('md')]: {
        //         // height: '106px',
        //         alignItems: 'center',
        //         '&.showing-invite-popup': {
        //             height: '170px',
        //         },
        //     },
        //     [theme.breakpoints.down('sm')]: {
        //         zIndex: 1,
        //         opacity: 1,
        //         width: '100%',
        //         '&.showing-invite-popup': {
        //             justifyContent: 'center',
        //             alignItems: 'center',
        //         },
        //     },
        // },
        textField: {
            backgroundColor: '#EEEEEE',
            borderRadius: '50px',
            margin: '0',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '25px',
            paddingRight: '25px',
            marginBottom: '10px',
            cursor: 'pointer',
            transition: 'background-color 0.5s ease',
            '&:disabled': {
                cursor: 'pointer',
            },
            '&.copied': {
                backgroundColor: theme.palette.background.default,
                transition: 'background-color 0.25s ease'
            },
            [theme.breakpoints.down('sm')]: {
                width: '200px',
            },
        },
        textInput: {
            color: '#111',
            fontSize: '15px',
            fontWeight: 600,
            padding: 0,
            width: '200px',
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            transition: 'color 0.5s ease',
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color, color',
            },
            '&.copied': {
                color: '#999999',
                transition: 'color 0.25s ease'
            }
        },
        inputAdornment: {
            cursor: 'pointer',
            '&.copied': {
                marginRight: '-6px',
                '& svg': {
                    width: '30px',
                    height: '30px',
                    '& rect': {
                        fill: '#00FF99',
                    }
                },
            }
        },
        tooltip: {
            lineHeight: '11.42px',
            fontSize: '10px',
            background: '#111',
            color: '#FFF',
            padding: '15px 20px 13px',
            borderRadius: '24px',
            fontWeight: 500,
            textTransform: 'uppercase',
        },
        tooltipTouch: {
            fontSize: '10px',
            lineHeight: '11.42px',
        },
        arrow: {
            color: '#111',
        },
        fab: {
            backgroundColor: '#fff',
            color: '#111',
            margin: '5px',
            boxShadow: 'none',
            '& .MuiFab-label': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            [theme.breakpoints.down('md')]: {
                height: '50px',
                width: '50px',
                margin: '5px 7.5px',
                backgroundColor: '#fff',
                color: '#111',
            },
            '&.device:hover, &.device:focus': {
                backgroundColor: '#fff',
                color: '#111',
            },
            '&:hover': {
                [theme.breakpoints.down('md')]: {
                    // backgroundColor: '#fff',
                    color: '#fff',
                    fill: '#fff',
                },
            },
        },
        inviteIcon: {
            width: '35px',
            height: '35px',
            display: 'flex',
        },
        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        mobile: {
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
        }
    })
);

export default function StreamInviteButton() {
    const classes = useStyles();
    let refEl = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [copied, setCopied] = useState(false);

    const isUserActive = useIsUserActive();
    const showInviteButton = isMobile || isUserActive;

    const url = window.location.href;
    const copyText = 'Join this Vibe session with me: ' + url

    const handleTogglePopup = useCallback(() => {
        setShowPopup(!showPopup)
        setCopied(false);
    }, [showPopup]);

    const handleCopyClick = useCallback(() => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 7000);
    }, []);

    const handleShare = useCallback(() => {
        if (navigator.share) {
            navigator.share({
                title: process.env.REACT_APP_PAGE_TITLE,
                text: 'Join this Vibe session with me',
                url,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            setShowPopup(true);
        }
    });
    return (
        <div className={clsx(classes.container, { 'showing-invite-popup': showPopup }, { showInviteButton })} ref={refEl}>
            <Button
                className={clsx(classes.inviteButton, classes.desktop, { [classes.showPopup]: showPopup })}
                type="button"
                onClick={handleTogglePopup}>
                INVITE
            </Button>
            <Tooltip
                className={classes.mobile}
                title={navigator.share ? 'SHARE' : 'INVITE'}
                placement="top"
                PopperProps={{ disablePortal: true }}
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow, touch: classes.tooltipTouch }}
                arrow>
                <Fab onClick={handleShare} className={clsx(classes.fab, classes.mobile)}>
                    <IconInvite className={classes.inviteIcon} />
                </Fab>
            </Tooltip>
            <Dialog
                classes={{ paper: classes.paper }}
                open={showPopup}
                onClose={handleTogglePopup}
                anchorEl={refEl}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.header}>
                    <span className={classes.title}>INVITE FRIENDS</span>
                    <button className={classes.closeButton} type="button" onClick={handleTogglePopup}>
                        <IconClose className={classes.closeButton} />
                    </button>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <CopyToClipboard onCopy={handleCopyClick} text={copyText}>
                        <TextField
                            value={url}
                            className={clsx(classes.textField, { ['copied']: copied })}
                            margin="dense"
                            InputProps={{
                                disableUnderline: true,
                                classes: {
                                    input: clsx(classes.textInput, { ['copied']: copied }),
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={clsx(classes.inputAdornment, { ['copied']: copied })}>
                                        {copied ? <IconCheckmark /> : <IconCopy />}
                                    </InputAdornment>
                                ),
                            }}
                            disabled={true}
                            variant="standard"
                        />
                    </CopyToClipboard>
                </DialogContent>
            </Dialog>
        </div>
    );
}
