import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

//import { isMobile, isIOS } from '../../utils';

import StreamEndButton from './StreamEndButton';
import StreamChatButton from './StreamChatButton';
import useIsUserActive from './hooks/useIsUserActive/useIsUserActive';
import StreamInviteButton from './StreamInviteButton';
import { isIOS, isMobile } from './utils';
import _ from 'lodash';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      zIndex: 5,
      transition: 'opacity 1.2s, visibility 0s 1.2s',
      opacity: 0,
      '&.showControls, &:hover': {
        transition: 'opacity 0.6s, visibility 0s',
        opacity: 1,
        visibility: 'visible',
      },
      [theme.breakpoints.down('xs')]: {
        zIndex: 1,
        opacity: 1,
        visibility: 'visible',
        '&.multiMenuOpen': {
          zIndex: 5,
        },
      },
    },
    centerContainer: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%, 0px)',
      bottom: '40px',
      maxWidth: 'min-content',
      [theme.breakpoints.down('md')]: {
        bottom: '0',
        height: '106px',
        alignItems: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        bottom: '0',
        height: '106px',
        alignItems: 'center',
      },
    },
    rightContainer: {
      display: 'flex',
      position: 'absolute',
      right: '40px',
      bottom: '40px',
      [theme.breakpoints.down('md')]: {
        bottom: '0',
        height: '106px',
        alignItems: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mobile: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'inline',
      },
    }
  })
);

export default function PlayerMenuBar({ roomName }) {
  const classes = useStyles();

  const isUserActive = useIsUserActive();
  const showControls = isUserActive || isMobile || isIOS;
  const [multiMenuOpen, setMultiMenuOpen] = useState(false);

  const vibeChatOn = !_.isEmpty(process.env.REACT_APP_CHAT_ROOM_NAME);

  return (
    <div className={clsx('vibe-controls-container')}>
      <div className={clsx(classes.centerContainer, classes.container, { showControls, multiMenuOpen })}>
        {vibeChatOn && <StreamChatButton />}
        <StreamEndButton />
        <div className={classes.mobile}><StreamInviteButton /></div>
      </div>
      <div className={classes.rightContainer}>
        <StreamInviteButton />
      </div>
    </div>
  );
}
