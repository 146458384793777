import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 900,
      textTransform: 'uppercase',
      letterSpacing: '-0.2px',
    }
  })
);

export default function NotFound() {

  const classes = useStyles();

  return (
    <div>
      <h2 className={classes.title}>Resource not found!</h2>
    </div>
  )
};
