import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconFlip(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 40 40" fill="none" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M24 11V17.5858C24 18.4767 25.0771 18.9229 25.7071 18.2929L27.293 16.707C27.7472 17.7113 28 18.8261 28 20C28 24.4183 24.4183 28 20 28C18.8954 28 18 28.8954 18 30C18 31.1046 18.8954 32 20 32C26.6274 32 32 26.6274 32 20C32 17.7113 31.3593 15.5723 30.2475 13.7525L32.2929 11.7071C32.9229 11.0771 32.4767 10 31.5858 10H25C24.4477 10 24 10.4477 24 11Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16 29V22.4142C16 21.5233 14.9229 21.0771 14.2929 21.7071L12.707 23.293C12.2528 22.2887 12 21.1739 12 20C12 15.5817 15.5817 12 20 12C21.1046 12 22 11.1046 22 10C22 8.89543 21.1046 8 20 8C19.9989 8 19.9978 8 19.9968 8C13.3708 8.00175 8 13.3737 8 20C8 22.2887 8.6407 24.4277 9.75253 26.2475L7.70711 28.2929C7.07714 28.9229 7.52331 30 8.41421 30H15C15.5523 30 16 29.5523 16 29Z" />
        </SvgIcon>
    );
}

export default IconFlip;
