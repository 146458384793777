import ChatAlertContainer from './ChatAlert/ChatAlertContainer';
import { ChatProvider } from './ChatProvider';
import { PlayerProvider } from './PlayerProvider';
import StreamInner from './StreamInner';

function StreamingApp () {

  return (
    <PlayerProvider>
      <ChatProvider>
        <StreamInner />
        <ChatAlertContainer />
      </ChatProvider>
    </PlayerProvider>
  )
}

export default StreamingApp;