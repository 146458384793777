import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Client } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { Message } from '@twilio/conversations/lib/message';
import { toast } from 'material-react-toastify';
import _ from 'lodash';
import NewChatAlert from '../ChatAlert/NewChatAlert';
import { Participant } from '@twilio/conversations/lib/participant';
import mql from '@microlink/mql';
import urlParser from 'url';
import { usePlayerState } from '../PlayerProvider';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import nodeConfig, { encodedAuth } from '../config/axios';

interface Options {
  ask?: boolean;
  host?: boolean;
  persistMins?: number;
  public?: boolean | undefined;
}

export interface ParticipantAttributes {
  name?: string;
}

interface LinkMetadata {
  asin?: string;
  author?: string;
  availability?: string;
  brand?: string;
  condition?: string;
  currency?: string;
  description?: string;
  hostname?: string;
  image?: string;
  logo?: string;
  mpn?: string;
  name?: string;
  price?: number;
  retailer?: string;
  sku?: number;
  title?: string;
  url?: string;
}

type Chat = {
  identity: any;
  conversationName: string;
  participantName: string;
  options: Options;
};

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  initializeChat: (chat: ChatProps) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: any;
  conversation: Conversation | null;
  vibeChat: Chat | null;
  newMessages: number;
  setNewMessages: React.Dispatch<React.SetStateAction<number>>;
  fetchPastMessages: () => void;
  linkCache: Map<string, LinkMetadata>;
  setLinkCache: React.Dispatch<React.SetStateAction<Map<string, LinkMetadata>>>;
  error: Error;
  localParticipantMessages: number[];
  addLocalParticipantMessage: (messageIndex: number) => void;
  typingParticipant: string | null;
  getLinkPreview: (url: string) => any;
  chatParticipants: Map<string, Participant>;
  setChatParticipants: React.Dispatch<React.SetStateAction<Map<string, Participant>>>;
};

const PAGE_SIZE = 50;

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: React.FC = ({ children }) => {
  const { playerState, viewerInfo, vibeConfig } = usePlayerState();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [chatClient, setChatClient] = useState<Client>();
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]); // Messages to be displayed based on role
  const [allMessages, setAllMessages] = useState<Message[]>([]); // Total unfiltered messages to track how many messages fetched
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [vibeChat, setVibeChat] = useState<Chat | null>(null);
  const [newMessages, setNewMessages] = useState<number>(0);
  const [typingParticipant, setTypingParticipant] = useState<string | null>(null);
  const [chatParticipants, setChatParticipants] = useState<Map<string, Participant>>(new Map());

  const [linkCache, setLinkCache] = useState<Map<string, LinkMetadata>>(new Map());
  const [localParticipantMessages, setLocalParticipantMessages] = useState<number[]>([]); // Stores message indices sent by local participant

  const [error, onError] = useState<any>(false);

  const connect = useCallback(
    (token: string) => {
      Client.create(token)
        .then(client => {
          //@ts-ignore
          window.chatClient = client;
          setChatClient(client);
        })
        .catch(() => {
          onError(new Error("There was a problem connecting to Twilio's conversation service."));
        });
    },
    [onError]
  );

  const initializeChat = async ({ identity, conversationName, participantName, options }: Chat) => {
    const headers = new window.Headers();
    const params = new window.URLSearchParams({ identity });

    setVibeChat({
      identity,
      conversationName,
      participantName,
      options,
    });

    const token = await (await fetch(`get-chat-token?${params}`, { headers: {...headers, 'Authorization': `Basic ${encodedAuth}`} })).text();
    connect(token);
  };

  useEffect(() => {
    // Handle case when refresh to reconnect to stream
    if (process.env.REACT_APP_CHAT_ROOM_NAME && !vibeChat && (playerState === 'connecting' || playerState === 'reconnecting')) {
      let formFieldValues = _.map(viewerInfo, ((field: any) => field.value))
      initializeChat({
        identity: formFieldValues.join('-')?.replace('@', '-'),
        conversationName: process.env.REACT_APP_CHAT_ROOM_NAME || '',
        participantName: viewerInfo[0].value || '',
        options: {
          ask: true,
          host: false,
          public: Boolean(process.env.REACT_APP_PUBLIC_VIBECHAT) || Boolean(vibeConfig?.vibechat?.public_chat)
        },
      })
    }
  }, []);

  const joinConversation = () => {
    try {
      const data = {
        conversationName: vibeChat?.conversationName,
        identity: vibeChat?.identity,
        participantName: vibeChat?.participantName,
      };

      fetch('/join-chat-conversation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${encodedAuth}`
        },
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(newConversation => {
          if (chatClient) {
            let uniqueName = newConversation.uniqueName || process.env.REACT_APP_CHAT_ROOM_NAME || ''
            chatClient.getConversationByUniqueName(uniqueName).then(newConversation => {
              //@ts-ignore
              window.chatConversation = newConversation;
              setConversation(newConversation);
              newConversation.getParticipants().then((participants: Participant[]) => {
                let chatParticipantsMap: Map<string, Participant> = new Map();
                _.forEach(participants, (p: Participant) => {
                  chatParticipantsMap.set(p.identity, p);
                });
                setChatParticipants(chatParticipantsMap);
              });
            });
            return newConversation;
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const addLocalParticipantMessage = (messageIndex: number) => {
    setLocalParticipantMessages((messageIndices) => localParticipantMessages ? [...messageIndices, messageIndex] : [messageIndex]);
  }

  // Filter messages based on role and if sent after reset date if it exists
  // Chat hosts can view all messages
  // If not public chat, guests can only view host messages and own messages
  // If public chat, guests can also view host messages, own messages, and public messages
  const isHostMessage = (message: Message) => JSON.parse(message.attributes?.toString())?.host;
  const isPublicMessage = (message: Message) => JSON.parse(message.attributes?.toString())?.public;
  const isLocalParticipantMessage = (message: Message) => _.includes(localParticipantMessages, message.index);
  const afterResetDate = (message: Message) => {
    const convAttr: any = conversation?.attributes;
    return !_.isEmpty(convAttr) ? new Date(convAttr.dateReset) < message.dateCreated : true;
  };
  const filterMessages = (messages: Message[]) => {
    return _.filter(messages, (m: Message) => {
      return (
        (isHostMessage(m) || isLocalParticipantMessage(m) || (vibeChat?.options.public && isPublicMessage(m))) &&
        afterResetDate(m)
      );
    });
  };

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) => {
        if (!chatParticipants.get(message.author)) {
          conversation.getParticipantByIdentity(message.author).then((participant: Participant) => {
            setChatParticipants(new Map(chatParticipants.set(participant.identity, participant)));
          });
        }

        setAllMessages((oldMessages: Message[]) => [...oldMessages, message]);

        // If not public chat, local particiant is not a host, and new message is not sent by a host or local participant, do not add message to stream
        if (
          !vibeChat?.options.public &&
          !vibeChat?.options.host &&
          !isHostMessage(message) &&
          !isLocalParticipantMessage(message)
        )
          return;

        setMessages((oldMessages: Message[]) => [...oldMessages, message]);

        // When menu window closed, update new message count and send message notification
        if (!isChatWindowOpen) {
          setNewMessages(oldCount => oldCount + 1);

          const participantAttributes: ParticipantAttributes | undefined = chatParticipants.get(message.author)
            ?.attributes;
          const authorName = participantAttributes?.name || '';
          toast(<NewChatAlert message={message} author={authorName} />, {
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };

      const handleMessageRemoved = (message: Message) => {
        const removeMessage = (messages: any) => _.filter(messages, (m: Message) => message.sid !== m.sid);
        setAllMessages((oldMessages: any) => removeMessage(oldMessages));
        setMessages((oldMessages: any) => removeMessage(oldMessages));
      };

      const handleTypingStarted = (participant: Participant) => {
        setTypingParticipant(participant.identity);
      };

      const handleTypingEnded = (participant: Participant) => {
        setTypingParticipant(null);
      };

      const handleConversationUpdated = (res: any) => {
        const { conversation, updateReasons } = res;
        if (_.includes(updateReasons, 'attributes')) {
          setConversation(conversation);
          setMessages([]);
        }
      };

      const handleParticipantJoined = (participant: Participant) => {
        setChatParticipants(new Map(chatParticipants.set(participant.identity, participant)));
      };

      conversation.getMessages(PAGE_SIZE).then(newMessages => {
        setAllMessages(newMessages.items);

        const messages: any = filterMessages(newMessages.items);
        setMessages(messages);
      });

      conversation.on('messageAdded', handleMessageAdded);
      conversation.on('messageRemoved', handleMessageRemoved);
      conversation.on('typingStarted', handleTypingStarted);
      conversation.on('typingEnded', handleTypingEnded);
      conversation.on('updated', handleConversationUpdated);
      conversation.on('participantJoined', handleParticipantJoined);

      return () => {
        conversation.off('messageAdded', handleMessageAdded);
        conversation.off('messageRemoved', handleMessageRemoved);
        conversation.off('typingStarted', handleTypingStarted);
        conversation.off('typingEnded', handleTypingEnded);
        conversation.off('updated', handleConversationUpdated);
        conversation.off('participantJoined', handleParticipantJoined);
      };
    }
  }, [conversation, isChatWindowOpen, localParticipantMessages]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      // if (!isChatWindowOpen && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (vibeChat && chatClient) {
      joinConversation();
    }
  }, [chatClient, onError]);

  const fetchPastMessages = async () => {
    if (conversation && !_.isEmpty(messages)) {
      // Check if already loaded all messages

      // Don't fetch anymore if already at date reset
      let oldestMessage: Message = allMessages[0];
      if (!afterResetDate(oldestMessage)) return;

      await conversation.getMessagesCount().then((count: number) => {
        if (count && count !== allMessages.length) {
          const newestIndex = (allMessages[0]?.index || 0) - 1;
          conversation.getMessages(PAGE_SIZE, newestIndex).then(pageOfMessages => {
            const prevMessages: any = pageOfMessages.items;
            const allMessages = [...prevMessages, ...messages];
            setAllMessages(allMessages);
            const displayMessages: any = filterMessages(allMessages);
            setMessages(displayMessages);
          });
        }
      });
    }
  };

  const getLinkPreview = async (url: string) => {
    try {
      const { status, data, response } = await mql(url, {
        apiKey: process.env.REACT_APP_MICROLINK_API_KEY,
        data: {
          favicon: {
            selector: 'link[href*="favicon.ico"]',
            attr: 'href',
            type: 'image',
          },
          avatar: {
            selector: 'meta[property="og:image"]',
            attr: 'content',
            type: 'image',
          },
          price: {
            selector: ['[property="og:price:amount"]', '[property="product:price:amount"]'],
            attr: 'content',
          },
        },
        headers: {
          'Authorization': `Basic ${encodedAuth}`
        }
        // screenshot: true,
      });

      return {
        ...data,
        hostname: urlParser.parse(url).hostname?.replace('www.', ''),
      };
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen,
        setIsChatWindowOpen,
        initializeChat,
        connect,
        hasUnreadMessages,
        conversation,
        messages,
        vibeChat,
        newMessages,
        setNewMessages,
        fetchPastMessages,
        linkCache,
        setLinkCache,
        error,
        localParticipantMessages,
        addLocalParticipantMessage,
        typingParticipant,
        getLinkPreview,
        chatParticipants,
        setChatParticipants,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
