import clsx from 'clsx';

import { createStyles, makeStyles } from '@material-ui/core/styles';

import { usePlayerState } from './PlayerProvider';
import useHeight from './hooks/useHeight/useHeight';

const useStyles = makeStyles((theme) =>
  createStyles({
    logoContainer: {
      height: '108px',
      // height: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      top: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 2,
      [theme.breakpoints.down('md')]: {
        zIndex: 2,
        top: 10,
        height: '106px',
        display: 'flex',
        alignItems: 'center',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        top: 0,
        height: '108px',
        display: 'flex',
        alignItems: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        // zIndex: 1,
        // top: 20,
        top: 0,
        height: '108px',
        display: 'flex',
        alignItems: 'center',
      },
      '.mobile--forced &, .hide-logo &': {
        zIndex: 0,
        opacity: 0,
      },
      '.room-disconnected &': {
        [theme.breakpoints.down('xs')]: {
          zIndex: 2,
        },
        '.mobile--forced &, .hide-logo &': {
          zIndex: 2,
        },
      },
    },
    logo: {
      width: (props) => (props.logoSizes && `${props.logoSizes[0]}px`) || '100px',
      height: 'auto',
      // width: (props: any) => `${props.width}px`,
      [theme.breakpoints.down('sm')]: {
        width: (props) =>
          props.logoSizes && props.logoSizes[1]
            ? `${props.logoSizes[1]}px`
            : props.logoSizes && props.logoSizes[0]
              ? `${props.logoSizes[0]}px`
              : '100px',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        width: (props) =>
          props.logoSizes && props.logoSizes[1]
            ? `${props.logoSizes[1]}px`
            : props.logoSizes && props.logoSizes[0]
              ? `${props.logoSizes[0]}px`
              : '100px',
      },
      [theme.breakpoints.down('xs')]: {
        width: (props) =>
          props.logoSizes && props.logoSizes[1]
            ? `${props.logoSizes[1]}px`
            : props.logoSizes && props.logoSizes[0]
              ? `${props.logoSizes[0]}px`
              : '100px',
      },
      '.mobile--forced &, .hide-logo &': {
        width: (props) =>
          props.logoSizes && props.logoSizes[1]
            ? `${props.logoSizes[1]}px`
            : props.logoSizes && props.logoSizes[0]
              ? `${props.logoSizes[0]}px`
              : '100px',
      },
    },
  })
);

export default function Logo({ source }) {
  const { vibeConfig } = usePlayerState();

  const logoSizes = vibeConfig?.logo?.width
    ? Object.values(vibeConfig?.logo?.width)
    : process.env.REACT_APP_LOGO_SIZES && process.env.REACT_APP_LOGO_SIZES.split('|');

  const classes = useStyles({ logoSizes });

  return (
    <div 
      className={clsx(classes.logoContainer, 'logo-container')}
    >
      <img
        className={clsx(classes.logo, 'dark')}
        src={source}
        alt=""
      />
    </div>
  );
}
